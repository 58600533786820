import React from "react";
const About = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <div>
        <svg
          width="695"
          height="685"
          viewBox="0 0 800 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M119.345 556.778V510.227C128.51 510.224 137.472 512.926 145.108 517.993C152.745 523.061 158.716 530.269 162.274 538.715C165.832 547.161 166.818 556.469 165.11 565.473C163.401 574.477 159.074 582.777 152.669 589.333C146.265 595.888 138.068 600.408 129.106 602.326C120.144 604.244 110.816 603.475 102.289 600.115C93.7626 596.755 86.417 590.954 81.1728 583.437C75.9286 575.921 73.0189 567.025 72.8082 557.863L119.345 556.778Z"
            fill="#E0E0E0"
          />
          <path
            d="M108.003 544.227L61.438 545.283C61.4356 532.841 66.1493 520.861 74.6291 511.756C83.306 503.054 94.9952 498.018 107.28 497.689L108.003 544.227Z"
            fill="#F5F5F5"
          />
          <path
            d="M606.791 103.555L641.693 98.2313C642.805 105.52 641.606 112.974 638.264 119.546C634.923 126.118 629.607 131.479 623.063 134.875C616.519 138.272 609.076 139.534 601.778 138.484C594.48 137.433 587.695 134.124 582.374 129.02C577.053 123.917 573.464 117.274 572.112 110.027C570.759 102.779 571.71 95.2896 574.831 88.6101C577.953 81.9305 583.088 76.3963 589.515 72.7843C595.943 69.1724 603.34 67.6641 610.669 68.4714L606.791 103.555Z"
            fill="#F5F5F5"
          />
          <path
            d="M618.119 68.5826L613.88 95.632L640.442 90.6419C640.442 90.6419 636.536 75.4909 618.119 68.5826Z"
            fill="#E0E0E0"
          />
          <path
            d="M90.8921 83.8726H71.5572V161.101H90.8921V83.8726Z"
            fill="#E0E0E0"
          />
          <path
            d="M119.331 100.719H99.9966V161.101H119.331V100.719Z"
            fill="#E0E0E0"
          />
          <path
            d="M148.202 114.508H128.867V161.101H148.202V114.508Z"
            fill="#E0E0E0"
          />
          <path
            d="M174.918 133.426H155.583V161.087H174.918V133.426Z"
            fill="#E0E0E0"
          />
          <path
            d="M406.102 365.209C406.007 356.715 408.43 348.384 413.066 341.266C417.701 334.149 424.34 328.563 432.147 325.216C439.953 321.868 448.576 320.907 456.928 322.455C465.279 324.003 472.985 327.99 479.073 333.914C485.161 339.837 489.358 347.431 491.134 355.737C492.911 364.043 492.187 372.689 489.054 380.584C485.922 388.479 480.521 395.27 473.533 400.098C466.545 404.927 458.283 407.577 449.79 407.715C438.365 407.856 427.349 403.461 419.16 395.492C410.97 387.524 406.274 376.634 406.102 365.209ZM469.653 364.18C469.568 360.119 468.281 356.175 465.956 352.845C463.631 349.515 460.371 346.948 456.589 345.468C452.807 343.989 448.671 343.663 444.703 344.531C440.735 345.4 437.114 347.424 434.296 350.349C431.478 353.274 429.589 356.968 428.868 360.965C428.147 364.962 428.626 369.083 430.245 372.808C431.864 376.533 434.55 379.695 437.964 381.895C441.378 384.095 445.368 385.234 449.429 385.169C452.135 385.12 454.806 384.538 457.287 383.457C459.769 382.376 462.013 380.816 463.891 378.866C465.769 376.917 467.245 374.617 468.234 372.097C469.222 369.577 469.705 366.887 469.653 364.18Z"
            fill="#F5F5F5"
          />
          <path
            d="M406.102 365.208C405.909 353.783 410.254 342.746 418.186 334.52C426.118 326.294 436.989 321.549 448.414 321.326L448.775 343.872C443.374 344.069 438.266 346.374 434.545 350.294C430.824 354.214 428.788 359.436 428.874 364.84C428.959 370.244 431.159 375.399 435.002 379.2C438.845 383 444.024 385.143 449.429 385.169L449.79 407.715C444.13 407.794 438.51 406.757 433.251 404.663C427.992 402.57 423.197 399.46 419.14 395.513C415.083 391.565 411.843 386.857 409.606 381.658C407.369 376.458 406.179 370.868 406.102 365.208Z"
            fill="#E0E0E0"
          />
          <path
            d="M444.55 317.865C451.082 317.71 457.579 318.859 463.662 321.246C469.744 323.633 475.289 327.209 479.973 331.765C484.656 336.322 488.383 341.767 490.936 347.781C493.489 353.796 494.816 360.259 494.84 366.793L468.597 367.224C468.374 353.908 458.241 345.512 444.967 345.721L444.55 317.865Z"
            fill="#EBEBEB"
          />
          <g filter="url(#filter0_d)">
            <path
              d="M636.481 649.658C636.481 649.867 507.1 650.02 347.514 650.02C187.928 650.02 58.5051 649.867 58.5051 649.658C58.5051 649.45 187.872 649.297 347.514 649.297C507.155 649.297 636.481 649.464 636.481 649.658Z"
              fill="#263238"
            />
          </g>
          <path
            d="M397.735 299.156L391.646 307.829L389.992 314.779L396.192 323.675L407.951 316.447L418.195 306.384C418.518 306.071 418.715 305.652 418.751 305.204C418.787 304.757 418.659 304.311 418.39 303.951L416.722 301.63L416.931 301.143C417.226 300.489 417.283 299.751 417.093 299.058C416.902 298.366 416.476 297.761 415.888 297.349L414.401 296.306L419.961 289.189C420.348 288.7 420.566 288.097 420.581 287.473C420.596 286.849 420.408 286.236 420.044 285.728L405.88 296.501L399.764 296.376L397.735 299.156Z"
            fill="#FFBE9D"
          />
          <path
            d="M417 301.421C416.459 302.088 415.799 302.649 415.054 303.076C414.293 303.614 413.483 304.079 412.635 304.466C412.073 304.716 411.446 304.784 410.842 304.66C410.504 304.581 410.195 304.409 409.949 304.163C409.703 303.918 409.531 303.608 409.452 303.27C409.332 302.601 409.482 301.911 409.869 301.352C410.178 300.883 410.532 300.446 410.926 300.045L412.802 298.03C413.332 297.37 413.955 296.789 414.651 296.306C414.265 297.062 413.768 297.755 413.177 298.363L411.426 300.476C410.759 301.255 409.953 302.13 410.147 303.117C410.197 303.339 410.311 303.541 410.474 303.699C410.636 303.857 410.842 303.964 411.065 304.007C411.53 304.114 412.017 304.071 412.455 303.882C413.278 303.528 414.077 303.119 414.846 302.659C415.522 302.177 416.243 301.763 417 301.421Z"
            fill="#EB996E"
          />
          <path
            d="M418.529 305.661C418.529 305.661 417.903 306.425 416.722 307.482C416.044 308.149 415.288 308.733 414.47 309.219C414.194 309.398 413.885 309.52 413.561 309.578C413.238 309.637 412.905 309.631 412.584 309.562C412.263 309.492 411.958 309.36 411.688 309.172C411.417 308.984 411.187 308.745 411.009 308.469C410.881 308.142 410.835 307.789 410.874 307.441C410.913 307.092 411.035 306.758 411.231 306.467C411.53 305.972 411.874 305.507 412.26 305.077C412.877 304.363 413.566 303.716 414.317 303.145C414.967 302.561 415.735 302.125 416.569 301.866C415.96 302.462 415.315 303.019 414.637 303.534C413.961 304.142 413.334 304.802 412.76 305.508C412.419 305.903 412.112 306.326 411.843 306.773C411.706 306.974 411.617 307.204 411.583 307.445C411.55 307.686 411.572 307.932 411.648 308.163C411.766 308.367 411.931 308.541 412.129 308.669C412.328 308.797 412.553 308.876 412.788 308.9C413.269 308.945 413.752 308.849 414.178 308.622C414.973 308.194 415.719 307.682 416.402 307.093C417.035 306.511 417.752 306.028 418.529 305.661Z"
            fill="#EB996E"
          />
          <path
            d="M401.71 310.776C402.438 310.034 403.056 309.192 403.545 308.274C404.012 307.232 404.227 306.093 404.17 304.952C404.229 303.545 403.845 302.154 403.072 300.977L402.53 300.268L403.406 300.421C404.193 300.545 404.995 300.545 405.783 300.421C406.889 300.251 407.938 299.817 408.841 299.156C409.625 298.575 410.153 297.713 410.314 296.751L410.62 297.085C408.521 297.015 406.783 296.946 405.56 296.876C404.919 296.883 404.279 296.808 403.656 296.654C404.286 296.523 404.931 296.471 405.574 296.501C406.797 296.501 408.535 296.501 410.62 296.501H410.953V296.834C410.888 297.403 410.706 297.952 410.419 298.447C410.132 298.942 409.746 299.372 409.285 299.712C408.301 300.453 407.143 300.932 405.922 301.102C405.062 301.255 404.182 301.255 403.322 301.102L403.642 300.546C404.509 301.845 404.919 303.394 404.81 304.952C404.832 306.174 404.55 307.382 403.99 308.469C403.611 309.182 403.096 309.815 402.474 310.331C402.057 310.679 401.779 310.804 401.71 310.776Z"
            fill="#EB996E"
          />
          <path
            d="M341.231 248.949C341.005 248.361 340.92 247.728 340.981 247.1C341.071 246.375 341.318 245.678 341.704 245.057C342.255 244.239 342.888 243.48 343.594 242.791C344.49 241.929 345.249 240.935 345.846 239.844C346.548 238.498 346.951 237.017 347.028 235.501C347.104 233.985 346.853 232.47 346.291 231.06C345.637 229.521 344.851 228.042 343.942 226.639C343.048 225.284 342.315 223.828 341.759 222.303C341.248 220.907 341.128 219.397 341.412 217.938C342.01 215.158 343.539 212.878 344.192 210.724C344.883 209.05 345.02 207.199 344.581 205.442C344.447 204.951 344.241 204.483 343.969 204.052C343.775 203.774 343.65 203.649 343.622 203.663C343.909 204.231 344.137 204.827 344.303 205.442C344.61 207.141 344.402 208.894 343.705 210.474C342.955 212.531 341.384 214.741 340.689 217.73C340.367 219.305 340.477 220.938 341.009 222.456C341.582 224.037 342.333 225.547 343.247 226.959C344.137 228.324 344.914 229.761 345.568 231.254C346.131 232.637 346.373 234.129 346.277 235.619C346.194 236.967 345.845 238.285 345.248 239.497C344.693 240.546 343.991 241.51 343.163 242.36C342.451 243.092 341.825 243.903 341.301 244.779C340.912 245.46 340.683 246.22 340.633 247.003C340.611 247.479 340.697 247.954 340.884 248.393C341.078 248.81 341.217 248.963 341.231 248.949Z"
            fill="#263238"
          />
          <path
            d="M301.95 237.69C301.502 238.915 300.705 239.982 299.658 240.761C298.61 241.539 297.359 241.993 296.056 242.068C294.754 242.109 293.462 241.833 292.29 241.264C291.118 240.695 290.102 239.85 289.329 238.802C287.79 236.669 287.133 234.026 287.494 231.421C287.742 230.285 287.821 229.119 287.73 227.96C287.452 226.32 286.159 225.013 285.659 223.429C284.755 220.565 286.701 217.382 289.398 216.062C292.202 214.913 295.311 214.742 298.225 215.575"
            fill="#263238"
          />
          <path
            d="M342.121 201.8C344.901 204.33 344.122 207.902 343.01 210.349C341.898 212.795 340.55 215.283 340.675 217.98C340.8 220.676 342.44 223.123 343.344 225.722C344.482 229.032 344.403 232.64 343.122 235.897C342.162 238.329 340.564 240.456 339.591 242.847C338.618 245.238 338.382 248.268 340.008 250.311C339.285 250.199 338.598 249.919 338.001 249.495C337.405 249.07 336.917 248.512 336.575 247.865C335.91 246.575 335.563 245.146 335.56 243.695C335.365 238.135 337.714 232.644 336.741 227.195C336.171 224.026 334.503 221.052 334.531 217.841C334.559 214.63 336.297 211.683 337.492 208.681C338.687 205.678 339.299 201.995 337.325 199.437"
            fill="#263238"
          />
          <path
            d="M334.99 191.695C336.639 191.755 338.251 192.198 339.699 192.989C341.146 193.781 342.39 194.899 343.33 196.254C344.231 197.638 344.609 199.298 344.398 200.935C344.187 202.573 343.399 204.082 342.176 205.192C339.73 207.11 336.102 206.735 333.392 205.192C331.36 204.093 329.766 202.332 328.874 200.202C328.447 199.135 328.282 197.981 328.393 196.837C328.505 195.693 328.889 194.592 329.513 193.627C330.165 192.677 331.095 191.953 332.176 191.555C333.256 191.156 334.434 191.103 335.546 191.403"
            fill="#263238"
          />
          <path
            d="M337.645 191.208C336.74 189.292 335.43 187.595 333.805 186.235C332.18 184.874 330.28 183.882 328.235 183.327C324.128 182.266 319.815 182.3 315.725 183.424C311.653 184.559 307.69 186.053 303.882 187.886C300.996 188.969 298.32 190.544 295.973 192.543C293.833 194.728 292.284 197.421 291.469 200.368C289.801 205.845 289.94 209.765 292.539 214.866C293.679 217.076 292.998 219.662 294.027 221.927C295.055 224.193 299.392 226.723 298.197 228.877"
            fill="#263238"
          />
          <path
            d="M328.791 259.833C328.944 248.713 328.93 244.543 328.944 244.543C328.957 244.543 338.521 242.93 340.467 230.434C341.44 224.221 341.106 214.018 340.564 205.692C340.077 198.2 335.087 189.012 327.623 189.818L304.299 195.768C303.204 195.883 302.193 196.406 301.467 197.232C300.74 198.059 300.352 199.129 300.379 200.229V259.805"
            fill="#FFBE9D"
          />
          <path
            d="M337.77 214.241C337.771 214.658 337.611 215.059 337.323 215.362C337.036 215.665 336.644 215.846 336.227 215.867C335.823 215.902 335.423 215.775 335.112 215.514C334.802 215.254 334.608 214.881 334.573 214.477C334.565 214.06 334.721 213.657 335.007 213.353C335.292 213.049 335.685 212.869 336.102 212.851C336.506 212.82 336.907 212.947 337.218 213.207C337.53 213.466 337.728 213.837 337.77 214.241Z"
            fill="#263238"
          />
          <path
            d="M338.368 210.21C338.173 210.418 336.978 209.515 335.226 209.529C333.475 209.543 332.238 210.404 332.057 210.196C331.876 209.987 332.168 209.723 332.71 209.32C333.457 208.801 334.345 208.524 335.254 208.528C336.153 208.52 337.031 208.803 337.756 209.334C338.284 209.737 338.465 210.112 338.368 210.21Z"
            fill="#263238"
          />
          <path
            d="M321.437 214.241C321.441 214.659 321.283 215.062 320.995 215.366C320.707 215.669 320.312 215.849 319.895 215.867C319.492 215.898 319.093 215.77 318.784 215.51C318.474 215.25 318.279 214.879 318.24 214.477C318.236 214.059 318.395 213.655 318.683 213.352C318.971 213.048 319.365 212.869 319.783 212.851C320.186 212.82 320.585 212.948 320.894 213.208C321.204 213.468 321.399 213.839 321.437 214.241Z"
            fill="#263238"
          />
          <path
            d="M321.41 211.377C321.215 211.586 320.02 210.682 318.268 210.696C316.517 210.71 315.28 211.572 315.099 211.363C314.918 211.155 315.21 210.891 315.752 210.488C316.499 209.968 317.387 209.692 318.296 209.695C319.194 209.692 320.071 209.975 320.798 210.502C321.326 210.905 321.507 211.28 321.41 211.377Z"
            fill="#263238"
          />
          <path
            d="M327.706 224.151C328.609 223.869 329.542 223.696 330.486 223.637C330.931 223.637 331.348 223.512 331.418 223.206C331.45 222.751 331.349 222.297 331.126 221.899L329.805 218.55C328.596 215.709 327.615 212.776 326.872 209.779C328.304 212.516 329.521 215.36 330.514 218.285C330.95 219.472 331.367 220.598 331.765 221.663C332.043 222.192 332.127 222.802 332.002 223.387C331.936 223.542 331.837 223.681 331.712 223.793C331.588 223.906 331.44 223.991 331.279 224.04C331.033 224.102 330.781 224.135 330.528 224.137C329.592 224.259 328.644 224.264 327.706 224.151Z"
            fill="#263238"
          />
          <path
            d="M328.943 244.557C323.058 244.665 317.259 243.135 312.194 240.136C312.194 240.136 316.433 248.713 328.943 247.517V244.557Z"
            fill="#EB996E"
          />
          <path
            d="M322.174 206.318C321.994 206.79 320.27 206.568 318.254 206.818C316.239 207.068 314.599 207.624 314.321 207.207C314.21 206.999 314.488 206.568 315.141 206.109C316.022 205.555 317.017 205.206 318.052 205.088C319.086 204.971 320.134 205.087 321.118 205.428C321.868 205.72 322.244 206.095 322.174 206.318Z"
            fill="#263238"
          />
          <path
            d="M337.77 206.026C337.464 206.415 336.269 206.026 334.837 206.026C333.405 206.026 332.21 206.29 331.918 205.887C331.626 205.484 331.988 205.289 332.516 204.914C333.226 204.466 334.054 204.244 334.893 204.274C335.733 204.283 336.55 204.555 337.228 205.053C337.687 205.428 337.853 205.831 337.77 206.026Z"
            fill="#263238"
          />
          <path
            d="M326.97 228.127C326.661 227.743 326.262 227.443 325.808 227.254C325.354 227.065 324.86 226.992 324.37 227.043C323.654 227.121 322.996 227.475 322.536 228.03C322.323 228.3 322.199 228.628 322.179 228.971C322.159 229.314 322.245 229.655 322.424 229.948C322.68 230.237 323.018 230.442 323.392 230.536C323.767 230.63 324.161 230.609 324.523 230.476C325.283 230.209 325.983 229.793 326.58 229.253C326.764 229.123 326.92 228.957 327.039 228.766C327.1 228.678 327.132 228.574 327.132 228.467C327.132 228.361 327.1 228.256 327.039 228.169"
            fill="#EB996E"
          />
          <path
            d="M322.911 225.361C323.175 225.361 323.175 227.098 324.676 228.335C326.177 229.572 328.026 229.378 328.04 229.614C328.054 229.85 327.637 229.962 326.845 229.975C325.832 229.988 324.848 229.643 324.065 229.003C323.296 228.385 322.797 227.493 322.675 226.514C322.591 225.792 322.786 225.361 322.911 225.361Z"
            fill="#263238"
          />
          <path
            d="M338.034 194.6C337.436 198.548 331.195 199.201 327.262 198.436C325.949 200.116 324.234 201.438 322.277 202.28C320.319 203.123 318.18 203.46 316.058 203.26C315.491 203.143 314.904 203.167 314.348 203.329C313.817 203.64 313.373 204.08 313.056 204.608C311.398 206.822 308.974 208.338 306.259 208.861C305.958 210.839 305.074 212.681 303.719 214.152C302.365 215.623 300.601 216.656 298.655 217.118C298.655 217.118 297.363 216.479 297.821 211.433C298.05 206.245 298.017 201.049 297.724 195.865L311.36 189.596L327.72 186.538C327.72 186.538 339.049 187.845 338.034 194.6Z"
            fill="#263238"
          />
          <path
            d="M292.748 215.45C292.863 214.244 293.149 213.06 293.596 211.933C294.144 210.653 294.993 209.523 296.07 208.639C297.443 207.492 299.127 206.78 300.907 206.596H301.116V206.373C301.588 204.179 302.718 202.182 304.354 200.646C305.259 199.811 306.387 199.256 307.601 199.049C308.815 198.842 310.062 198.991 311.193 199.479L311.582 199.673L311.721 199.256L311.874 198.812C312.519 197.134 313.611 195.664 315.033 194.564C316.454 193.463 318.15 192.774 319.936 192.571C320.742 192.496 321.554 192.52 322.355 192.64C323.174 192.779 324.014 192.736 324.815 192.515C325.547 192.223 326.189 191.743 326.678 191.125C327.08 190.604 327.547 190.137 328.068 189.735C328.877 189.162 329.799 188.769 330.772 188.582C331.746 188.395 332.748 188.419 333.711 188.651C334.423 188.852 335.113 189.127 335.768 189.471C335.768 189.471 335.629 189.318 335.31 189.082C334.835 188.776 334.321 188.538 333.781 188.373C332.769 188.067 331.701 187.993 330.656 188.157C329.612 188.32 328.618 188.717 327.748 189.318C326.594 190.041 325.913 191.5 324.607 191.945C323.3 192.39 321.632 191.681 319.853 191.945C317.93 192.148 316.101 192.878 314.568 194.056C313.035 195.233 311.857 196.812 311.165 198.617L310.999 199.104L311.527 198.881C310.267 198.326 308.871 198.151 307.513 198.378C306.155 198.605 304.893 199.224 303.882 200.16C302.146 201.803 300.965 203.945 300.504 206.29L300.768 206.04C298.876 206.266 297.098 207.061 295.667 208.319C293.884 209.914 292.801 212.146 292.651 214.533C292.632 214.842 292.665 215.152 292.748 215.45Z"
            fill="#455A64"
          />
          <path
            d="M314.335 202.203C314.655 202.387 315.01 202.506 315.377 202.551C316.351 202.744 317.349 202.796 318.338 202.704C319.998 202.569 321.612 202.091 323.078 201.302C324.545 200.512 325.832 199.428 326.858 198.117L326.4 198.228L326.803 198.409C328.56 199.143 330.499 199.327 332.363 198.937C333.88 198.6 335.286 197.882 336.449 196.852C337.226 196.168 337.872 195.349 338.354 194.433C338.565 194.112 338.699 193.745 338.743 193.363C338.012 194.507 337.128 195.545 336.116 196.449C334.988 197.367 333.651 197.993 332.224 198.27C330.486 198.6 328.689 198.416 327.053 197.741L326.678 197.561L326.4 197.436L326.219 197.672C324.247 200.159 321.387 201.784 318.24 202.203C316.94 202.306 315.634 202.306 314.335 202.203Z"
            fill="#455A64"
          />
          <path
            d="M321.382 194.809C321.382 195.031 323.217 194.809 325.343 195.378C327.47 195.948 329.027 196.768 329.152 196.588C328.203 195.536 326.913 194.852 325.51 194.656C324.158 194.224 322.698 194.278 321.382 194.809Z"
            fill="#455A64"
          />
          <path
            d="M326.108 194.405C326.275 194.53 326.914 194.002 327.72 193.432C328.526 192.862 329.221 192.404 329.11 192.209C328.999 192.015 328.137 192.209 327.248 192.835C326.358 193.46 325.955 194.28 326.108 194.405Z"
            fill="#455A64"
          />
          <path
            d="M306.69 201.675C306.759 201.87 308.344 201.355 310.345 201.425C312.347 201.494 313.89 202.064 313.973 201.87C312.909 201.133 311.654 200.722 310.36 200.688C309.067 200.653 307.791 200.996 306.69 201.675Z"
            fill="#455A64"
          />
          <path
            d="M303.228 208.361C304.949 208.615 306.706 208.384 308.302 207.694C309.982 207.239 311.5 206.319 312.68 205.039C312.555 204.872 310.651 206.095 308.052 206.999C305.452 207.902 303.215 208.153 303.228 208.361Z"
            fill="#455A64"
          />
          <path
            d="M294.68 213.143C295.718 212.457 296.805 211.849 297.933 211.322C299.137 211.011 300.364 210.797 301.602 210.682C300.992 210.385 300.321 210.23 299.642 210.23C298.963 210.23 298.293 210.385 297.682 210.682C295.528 211.391 294.513 213.073 294.68 213.143Z"
            fill="#455A64"
          />
          <path
            d="M294.93 195.198C294.93 195.198 295.139 194.781 295.584 194.114C296.243 193.194 297.103 192.438 298.099 191.903C301.497 190.25 305.233 189.412 309.011 189.457C312.803 189.472 316.577 189.972 320.242 190.944C323.022 191.653 324.815 192.195 324.843 192.084C324.487 191.852 324.098 191.674 323.689 191.556C322.911 191.25 321.785 190.861 320.381 190.444C316.694 189.304 312.857 188.723 308.997 188.72C305.113 188.679 301.284 189.636 297.877 191.5C296.838 192.1 295.975 192.963 295.375 194.002C295.131 194.358 294.979 194.769 294.93 195.198Z"
            fill="#455A64"
          />
          <path
            d="M293.915 201.967C294.445 200.735 295.092 199.558 295.848 198.45C297.845 195.901 300.607 194.059 303.729 193.196C306.873 192.397 310.132 192.148 313.362 192.459C314.713 192.629 316.073 192.722 317.434 192.737C316.132 192.314 314.783 192.047 313.417 191.945C310.115 191.466 306.751 191.651 303.52 192.487C300.25 193.388 297.389 195.385 295.417 198.145C294.837 198.996 294.396 199.935 294.11 200.925C293.979 201.256 293.913 201.61 293.915 201.967Z"
            fill="#455A64"
          />
          <path
            d="M340.745 207.193C341.684 206.892 342.496 206.285 343.052 205.47C343.83 204.55 344.373 203.456 344.636 202.281C344.898 201.106 344.872 199.884 344.559 198.722C344.246 197.559 343.656 196.489 342.839 195.604C342.022 194.719 341.003 194.046 339.869 193.641C338.973 193.214 337.961 193.096 336.992 193.307C337.9 193.511 338.792 193.78 339.66 194.113C340.677 194.541 341.582 195.195 342.307 196.025C343.033 196.855 343.559 197.84 343.846 198.905C344.133 199.969 344.173 201.085 343.963 202.167C343.753 203.25 343.299 204.27 342.635 205.15C342.048 205.87 341.417 206.552 340.745 207.193Z"
            fill="#455A64"
          />
          <path
            d="M289.732 226.82C289.801 226.695 288.494 226.111 286.632 224.874C285.555 224.148 284.645 223.2 283.963 222.094C283.554 221.449 283.3 220.718 283.22 219.959C283.14 219.199 283.238 218.431 283.504 217.716C284.043 216.263 285.131 215.079 286.535 214.421C287.709 213.924 289.001 213.77 290.26 213.977C291.436 214.228 292.573 214.64 293.637 215.2C293.637 215.2 293.415 214.894 292.887 214.519C292.116 214.014 291.258 213.655 290.357 213.462C288.988 213.157 287.558 213.273 286.257 213.796C285.464 214.144 284.751 214.648 284.158 215.279C283.566 215.909 283.107 216.653 282.809 217.465C282.506 218.284 282.4 219.162 282.499 220.028C282.598 220.895 282.899 221.727 283.379 222.456C284.143 223.599 285.161 224.551 286.354 225.236C287.129 225.707 287.943 226.111 288.786 226.445C289.37 226.765 289.704 226.862 289.732 226.82Z"
            fill="#263238"
          />
          <path
            d="M289.12 226.917C288.897 227.262 288.724 227.637 288.606 228.029C288.212 229.065 287.883 230.123 287.619 231.199C287.201 232.813 287.028 234.481 287.104 236.147C287.194 238.231 287.895 240.242 289.12 241.929C289.756 242.772 290.56 243.472 291.481 243.987C292.403 244.501 293.421 244.818 294.471 244.918C295.334 244.992 296.202 244.861 297.005 244.535C297.807 244.209 298.52 243.697 299.086 243.041C299.87 242.188 300.328 241.086 300.379 239.928C300.42 239.507 300.343 239.084 300.157 238.705C300.087 238.705 300.157 239.149 300.087 239.9C299.894 241.198 299.209 242.372 298.174 243.18C297.139 243.987 295.833 244.365 294.527 244.237C293.577 244.125 292.659 243.82 291.83 243.342C291.001 242.864 290.277 242.223 289.704 241.457C288.571 239.88 287.907 238.015 287.786 236.078C287.677 234.471 287.794 232.857 288.133 231.282C288.717 228.613 289.273 226.959 289.12 226.917Z"
            fill="#263238"
          />
          <path
            d="M306.453 208.847C308.135 211.78 307.176 215.492 305.8 218.577C304.424 221.663 302.603 224.721 302.673 228.099C302.77 233.756 308.093 238.454 307.704 244.098C307.562 245.78 306.917 247.38 305.853 248.691C304.79 250.003 303.358 250.964 301.741 251.451C304.313 249.505 304.341 245.571 303.131 242.583C301.922 239.594 296.557 231.824 295.653 228.683C292.665 218.286 293.624 217.007 297.043 213.087"
            fill="#263238"
          />
          <path
            d="M296.723 216.923C296.723 217.021 297.474 217.146 298.683 216.923C300.266 216.586 301.711 215.784 302.836 214.62C303.96 213.456 304.711 211.984 304.994 210.39C305.202 209.181 304.994 208.43 304.994 208.43C304.792 209.035 304.634 209.652 304.521 210.279C304.126 211.717 303.375 213.032 302.338 214.103C301.301 215.175 300.01 215.968 298.586 216.409C297.954 216.537 297.331 216.709 296.723 216.923Z"
            fill="#455A64"
          />
          <path
            d="M302.867 248.032C302.867 248.032 303.006 247.962 303.228 247.768C303.544 247.483 303.824 247.161 304.062 246.808C304.951 245.472 305.367 243.877 305.244 242.277C305.179 241.163 304.835 240.082 304.243 239.136C303.532 238.105 302.691 237.171 301.741 236.356C300.738 235.503 299.904 234.471 299.281 233.312C298.654 232.064 298.316 230.691 298.294 229.294C298.279 227.896 298.598 226.514 299.225 225.263C299.853 224.089 300.583 222.973 301.408 221.927C302.855 220.12 304.03 218.111 304.897 215.964C305.473 214.503 305.775 212.948 305.786 211.377C305.805 210.799 305.73 210.222 305.564 209.668C305.589 211.758 305.196 213.833 304.41 215.77C303.494 217.838 302.297 219.77 300.852 221.51C299.99 222.581 299.232 223.731 298.586 224.944C297.896 226.289 297.543 227.782 297.557 229.294C297.575 230.809 297.946 232.299 298.642 233.645C299.305 234.873 300.197 235.963 301.269 236.856C302.198 237.612 303.025 238.486 303.729 239.455C304.285 240.311 304.624 241.289 304.716 242.305C304.877 243.808 304.566 245.324 303.826 246.642C303.284 247.573 302.825 247.99 302.867 248.032Z"
            fill="#455A64"
          />
          <path
            d="M295.472 216.604C295.472 216.604 295.153 216.798 294.736 217.299C294.144 218.019 293.674 218.831 293.346 219.703C292.308 222.623 292.347 225.818 293.457 228.711C294.594 231.541 296.297 234.109 298.461 236.258C299.271 237.205 300.206 238.038 301.241 238.733C300.492 237.757 299.684 236.829 298.822 235.953C296.836 233.768 295.25 231.251 294.138 228.516C293.101 225.763 292.989 222.748 293.818 219.926C294.255 218.765 294.809 217.652 295.472 216.604Z"
            fill="#455A64"
          />
          <path
            d="M390.187 307.996L379.72 312.958L366.515 320.061C361.039 304.035 361.275 286.952 359.315 280.474C353.157 260.125 341.759 258.345 341.759 258.345V258.512C341.431 258.276 341.071 258.089 340.689 257.956L331.07 254.968L330.764 249.741L298.252 250.506L297.974 257.067L287.73 258.776C284.563 259.261 281.556 260.492 278.958 262.367C276.36 264.243 274.245 266.709 272.787 269.563C272.579 269.966 272.398 270.397 272.218 270.828C265.518 287.257 250.853 323.411 249.713 327.234C245.766 340.619 259.986 352.365 272.398 349.627C274.842 348.829 277.241 347.901 279.585 346.847C279.555 348.49 279.876 350.121 280.525 351.631C281.175 353.141 282.138 354.495 283.352 355.604C284.293 356.581 285.547 357.196 286.896 357.341L346.513 357.689C348.125 357.689 349.821 356.299 351.183 354.811C353.927 355.95 356.965 356.169 359.843 355.437C365 354.394 370.379 351.489 371.63 350.238L401.863 321.799L390.187 307.996ZM285.701 317.087L285.158 319.7L281.836 319.922L285.77 306.787V316.281C285.775 316.551 285.752 316.821 285.701 317.087Z"
            fill="#CFAAFF"
          />
          <path
            d="M301.143 318.644L299.753 318.783L295.695 319.144L281.92 320.27H281.378L281.558 319.756C281.989 318.463 282.406 317.128 282.837 315.738C284.7 309.511 286.284 303.812 287.563 299.74C288.203 297.696 288.745 296.056 289.148 294.93C289.316 294.328 289.559 293.749 289.871 293.207C289.772 293.821 289.619 294.426 289.412 295.014C289.051 296.306 288.606 297.933 288.022 299.879C286.882 304.049 285.381 309.706 283.49 315.947C283.073 317.337 282.643 318.727 282.198 319.978L281.836 319.505C287.396 319.102 292.178 318.796 295.639 318.63C299.1 318.463 301.199 318.463 301.143 318.644Z"
            fill="#263238"
          />
          <path
            d="M307.829 333.6C307.396 333.921 306.93 334.196 306.439 334.42L302.464 336.463C299.1 338.159 294.541 340.633 289.384 343.288C284.981 345.538 280.361 347.335 275.595 348.654C273.774 349.126 272.273 349.488 271.231 349.668C270.702 349.82 270.155 349.9 269.604 349.905C269.604 349.793 271.856 349.251 275.456 348.153C280.146 346.701 284.699 344.84 289.064 342.593C294.193 340.008 298.794 337.548 302.242 335.963C303.951 335.157 305.341 334.573 306.328 334.128C306.809 333.901 307.312 333.724 307.829 333.6Z"
            fill="#263238"
          />
          <path
            d="M345.207 314.738C345.081 314.668 346.318 312.917 347.514 309.72C349.053 305.567 349.577 301.106 349.043 296.709C348.68 294.294 348.188 291.9 347.569 289.537C347.027 287.341 346.569 285.367 346.332 283.63C346.124 282.278 346.031 280.911 346.054 279.543C346.031 279.041 346.092 278.538 346.235 278.056C346.338 279.918 346.538 281.774 346.833 283.616C347.139 285.284 347.639 287.257 348.223 289.44C348.894 291.822 349.409 294.246 349.766 296.696C350.334 301.203 349.726 305.781 348 309.984C347.485 311.247 346.857 312.461 346.124 313.612C345.874 314.029 345.565 314.409 345.207 314.738Z"
            fill="#263238"
          />
          <path
            d="M345.512 315.738C344.776 317.847 344.496 320.088 344.692 322.313C344.94 324.713 345.563 327.058 346.541 329.263C347.583 331.793 349.043 334.448 350.377 337.353C351.722 340.076 352.587 343.01 352.935 346.027C353.202 348.529 352.718 351.055 351.545 353.282C350.781 354.789 349.63 356.066 348.209 356.98C347.824 357.222 347.414 357.423 346.986 357.577C346.694 357.675 346.541 357.716 346.527 357.689C346.513 357.661 347.111 357.411 348.056 356.743C349.359 355.78 350.405 354.51 351.1 353.046C352.138 350.886 352.533 348.474 352.24 346.096C351.866 343.166 351.006 340.32 349.696 337.673C348.376 334.795 346.916 332.113 345.902 329.527C344.925 327.25 344.333 324.826 344.15 322.355C344.028 320.689 344.222 319.015 344.72 317.42C344.851 316.983 345.023 316.56 345.234 316.155C345.401 315.877 345.485 315.738 345.512 315.738Z"
            fill="#263238"
          />
          <path
            d="M345.679 261.945C346.232 263.629 346.426 265.409 346.249 267.172C346.304 268.943 345.982 270.705 345.304 272.343C345.267 270.607 345.341 268.87 345.526 267.144C345.469 265.41 345.52 263.673 345.679 261.945Z"
            fill="#263238"
          />
          <path
            d="M362.095 338.465C361.736 337.581 361.538 336.639 361.511 335.685C361.301 333.398 361.436 331.092 361.914 328.846C362.389 326.604 363.204 324.447 364.333 322.452C364.74 321.59 365.306 320.812 366.001 320.159C362.829 325.707 361.464 332.105 362.095 338.465Z"
            fill="#263238"
          />
          <path
            d="M290.927 272.343C290.788 272.343 290.788 271.453 290.176 270.202C289.336 268.593 288.126 267.207 286.646 266.157C285.224 265.034 283.565 264.249 281.795 263.864C281.491 263.818 281.184 263.79 280.877 263.78C280.613 263.78 280.377 263.78 280.182 263.78C279.988 263.78 279.612 263.933 279.585 263.892C279.557 263.85 279.724 263.725 280.099 263.502C280.343 263.417 280.595 263.352 280.85 263.308C281.183 263.279 281.517 263.279 281.85 263.308C283.767 263.567 285.578 264.34 287.091 265.546C288.67 266.654 289.903 268.186 290.649 269.966C291.01 270.703 291.108 271.542 290.927 272.343Z"
            fill="#263238"
          />
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M289.912 315.836C288.648 310.234 288.106 304.493 288.3 298.753L287.772 299.795L285.77 306.745L285.159 319.658L296.696 319.158C294.796 317.429 292.443 316.277 289.912 315.836Z"
              fill="black"
            />
          </g>
          <path
            d="M330.639 255.927C330.736 256.146 330.786 256.382 330.786 256.622C330.786 256.861 330.736 257.098 330.639 257.317C330.435 257.922 330.095 258.473 329.644 258.926C329.193 259.379 328.645 259.723 328.04 259.93C324.077 261.19 319.938 261.805 315.78 261.751C311.628 261.734 307.501 261.102 303.534 259.874C302.339 259.503 301.165 259.066 300.018 258.568C299.586 258.404 299.175 258.189 298.794 257.928C298.794 257.817 300.657 258.554 303.687 259.318C307.631 260.38 311.696 260.932 315.78 260.959C319.86 260.997 323.925 260.469 327.859 259.388C328.436 259.232 328.97 258.949 329.422 258.559C329.874 258.169 330.233 257.683 330.473 257.136C330.583 256.742 330.639 256.336 330.639 255.927Z"
            fill="#263238"
          />
          <path
            d="M274.886 299.781C274.012 298.613 273.349 297.3 272.927 295.903C272.276 294.591 271.84 293.183 271.634 291.733C272.5 292.907 273.163 294.218 273.594 295.611C274.25 296.921 274.687 298.33 274.886 299.781Z"
            fill="#FAFAFA"
          />
          <path
            d="M268.27 318.435C266.823 319.245 265.269 319.848 263.655 320.228C262.115 320.832 260.495 321.207 258.846 321.34C260.287 320.529 261.836 319.925 263.447 319.547C264.991 318.941 266.616 318.566 268.27 318.435Z"
            fill="#FAFAFA"
          />
          <path
            d="M266.672 337.45C266.348 336.085 266.268 334.673 266.435 333.28C266.35 331.873 266.51 330.462 266.908 329.11C267.233 330.475 267.317 331.886 267.158 333.28C267.235 334.688 267.07 336.098 266.672 337.45Z"
            fill="#FAFAFA"
          />
          <path
            d="M289.759 331.848C288.582 331.075 287.495 330.17 286.521 329.152C285.395 328.302 284.373 327.322 283.477 326.233C286.033 327.487 288.204 329.408 289.759 331.793V331.848Z"
            fill="#FAFAFA"
          />
          <path
            d="M306.05 322.105C306.189 322.244 305.313 323.411 304.076 324.718C302.839 326.024 301.727 326.97 301.574 326.831C301.422 326.692 302.311 325.524 303.548 324.217C304.785 322.911 305.897 321.966 306.05 322.105Z"
            fill="#FAFAFA"
          />
          <path
            d="M283.268 275.109C281.132 277.041 278.689 278.606 276.04 279.737C278.167 277.792 280.612 276.227 283.268 275.109Z"
            fill="#FAFAFA"
          />
          <path
            d="M301.741 270.786C300.951 269.514 300.316 268.151 299.851 266.727C299.161 265.404 298.629 264.005 298.266 262.557C299.271 263.695 300.037 265.023 300.518 266.463C301.2 267.812 301.616 269.28 301.741 270.786Z"
            fill="#FAFAFA"
          />
          <path
            d="M303.451 293.929C301.904 293.467 300.46 292.712 299.198 291.705C297.802 290.895 296.579 289.818 295.597 288.536C297.016 289.254 298.358 290.116 299.601 291.108C300.979 291.912 302.269 292.858 303.451 293.929Z"
            fill="#FAFAFA"
          />
          <path
            d="M316.711 305.188C315.1 306.458 313.341 307.527 311.471 308.371C309.726 309.438 307.859 310.292 305.911 310.915C307.537 309.659 309.299 308.591 311.165 307.732C312.914 306.681 314.774 305.828 316.711 305.188Z"
            fill="#FAFAFA"
          />
          <path
            d="M322.925 291.692C322.288 290.53 321.865 289.263 321.674 287.952C321.255 286.7 321.071 285.38 321.132 284.06C321.768 285.222 322.192 286.489 322.383 287.8C322.802 289.052 322.986 290.372 322.925 291.692Z"
            fill="#FAFAFA"
          />
          <path
            d="M339.744 304.493C338.766 303.373 337.977 302.101 337.409 300.726C336.632 299.458 336.064 298.074 335.727 296.626C336.703 297.753 337.491 299.029 338.062 300.407C338.828 301.675 339.395 303.053 339.744 304.493Z"
            fill="#FAFAFA"
          />
          <path
            d="M337.367 267.714C336.4 268.851 335.255 269.823 333.975 270.591C332.834 271.547 331.548 272.316 330.167 272.871C332.268 270.769 334.7 269.026 337.367 267.714Z"
            fill="#FAFAFA"
          />
          <path
            d="M321.66 268.77C321.479 268.868 320.826 267.881 320.27 266.574C319.714 265.268 319.283 264.156 319.464 264.072C319.644 263.989 320.298 264.962 320.923 266.268C321.549 267.575 321.841 268.687 321.66 268.77Z"
            fill="#FAFAFA"
          />
          <path
            d="M312.194 254.884C312.194 255.079 311.11 255.301 309.748 255.357C308.385 255.412 307.273 255.357 307.259 255.107C307.246 254.856 308.344 254.69 309.72 254.634C311.096 254.578 312.194 254.69 312.194 254.884Z"
            fill="#FAFAFA"
          />
          <path
            d="M323.189 255.107C323.036 254.981 323.509 254.092 324.245 253.091C324.982 252.09 325.719 251.395 325.886 251.52C326.052 251.646 325.566 252.535 324.829 253.536C324.092 254.537 323.356 255.232 323.189 255.107Z"
            fill="#FAFAFA"
          />
          <path
            d="M353.533 283.56C353.01 282.172 352.723 280.706 352.685 279.223C352.392 277.772 352.35 276.282 352.56 274.817C353.346 277.661 353.674 280.612 353.533 283.56Z"
            fill="#FAFAFA"
          />
          <path
            d="M357.091 300.865C356.952 301.004 355.59 299.892 354.311 298.211C353.405 297.265 352.737 296.117 352.365 294.861C354.11 296.737 355.709 298.744 357.147 300.865H357.091Z"
            fill="#FAFAFA"
          />
          <path
            d="M352.115 318.588C352.466 317.212 353.039 315.902 353.811 314.71C354.386 313.411 355.175 312.217 356.146 311.179C355.807 312.56 355.233 313.871 354.45 315.057C353.884 316.362 353.093 317.557 352.115 318.588Z"
            fill="#FAFAFA"
          />
          <path
            d="M358.106 344.359C357.191 342.949 356.489 341.413 356.021 339.799C355.336 338.264 354.869 336.64 354.631 334.976C355.545 336.386 356.248 337.922 356.716 339.535C357.4 341.071 357.868 342.694 358.106 344.359Z"
            fill="#FAFAFA"
          />
          <path
            d="M376.134 334.99C373.706 334.396 371.42 333.329 369.406 331.849C371.834 332.442 374.12 333.51 376.134 334.99Z"
            fill="#FAFAFA"
          />
          <path
            d="M386.267 325.329C385.977 323.866 386.044 322.355 386.462 320.923C386.636 319.43 387.165 318 388.005 316.753C387.857 318.203 387.578 319.636 387.171 321.034C386.991 322.489 386.689 323.926 386.267 325.329Z"
            fill="#FAFAFA"
          />
          <path
            d="M326.65 319.074C326.884 317.684 327.354 316.345 328.04 315.113C328.528 313.779 329.248 312.541 330.167 311.457C329.899 312.85 329.401 314.189 328.693 315.419C328.226 316.745 327.535 317.982 326.65 319.074Z"
            fill="#FAFAFA"
          />
          <path
            d="M297.835 352.031C296.778 351.184 295.884 350.153 295.194 348.987C294.306 347.964 293.605 346.792 293.123 345.526C294.178 346.379 295.077 347.409 295.778 348.57C296.662 349.594 297.359 350.766 297.835 352.031Z"
            fill="#FAFAFA"
          />
          <path
            d="M311.471 348.431C311.704 346.9 312.173 345.413 312.861 344.025C313.317 342.541 313.997 341.135 314.877 339.855C314.221 342.862 313.085 345.744 311.513 348.39L311.471 348.431Z"
            fill="#FAFAFA"
          />
          <path
            d="M333.503 352.282C330.408 351.66 327.587 350.079 325.441 347.764C326.843 348.409 328.191 349.167 329.472 350.03C330.87 350.678 332.218 351.43 333.503 352.282Z"
            fill="#FAFAFA"
          />
          <path
            d="M342.51 338.118C340.976 335.308 340.254 332.126 340.425 328.93C340.93 330.418 341.302 331.948 341.537 333.503C341.994 335.01 342.319 336.554 342.51 338.118Z"
            fill="#FAFAFA"
          />
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M279.793 349.377C283.01 350.957 286.698 351.294 290.149 350.322C293.56 349.331 296.803 347.831 299.767 345.874C301.456 344.698 303.338 343.828 305.327 343.302C307.575 343.016 309.856 343.176 312.041 343.775C317.254 344.817 322.689 345.665 327.832 344.345C332.975 343.024 337.784 339.021 338.465 333.753C338.729 331.626 338.02 329.055 335.991 328.374C334.652 327.819 333.252 327.422 331.821 327.192C309.72 328.985 279.668 346.888 279.696 347.5"
              fill="black"
            />
          </g>
          <path
            d="M379.748 599.98L385.35 643.487L365.639 644.738L355.506 602.996L379.748 599.98Z"
            fill="#FFBE9D"
          />
          <path
            d="M363.791 636.3C363.791 636.3 378.858 644.182 384.363 634.91L386.656 638.761L398.43 666.853C398.819 667.73 398.876 668.718 398.59 669.634C398.303 670.55 397.693 671.33 396.873 671.829C396.239 672.187 395.517 672.357 394.79 672.318C394.063 672.278 393.363 672.031 392.772 671.606C386.378 666.992 362.985 649.922 362.818 647.198L361.928 640.026C361.841 639.3 361.972 638.565 362.303 637.914C362.635 637.263 363.153 636.726 363.791 636.37V636.3Z"
            fill="#CFAAFF"
          />
          <path
            opacity="0.6"
            d="M396.219 672.037L362.47 644.64L362.693 646.197C362.776 647.921 363.61 648.491 364.555 649.464C367.335 652.355 374.966 658.652 392.661 671.551C393.168 671.919 393.758 672.157 394.379 672.242C395 672.327 395.632 672.256 396.219 672.037Z"
            fill="white"
          />
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M366.168 639.4C366.664 639.672 367.044 640.116 367.236 640.648C367.428 641.181 367.419 641.765 367.21 642.291C366.96 642.802 366.524 643.197 365.991 643.396C365.459 643.595 364.87 643.583 364.347 643.362C363.817 643.072 363.412 642.597 363.21 642.028C363.007 641.459 363.021 640.835 363.249 640.276C363.565 639.771 364.065 639.408 364.644 639.266C365.222 639.123 365.834 639.211 366.348 639.511"
              fill="white"
            />
          </g>
          <path
            d="M385.53 657.109C385.767 657.178 386.323 655.135 388.31 653.787C389.601 653.066 391.015 652.594 392.48 652.397C392.48 652.299 391.994 652.077 391.09 652.077C389.936 652.11 388.816 652.477 387.866 653.133C386.908 653.769 386.158 654.673 385.711 655.733C385.419 656.539 385.447 657.095 385.53 657.109Z"
            fill="#263238"
          />
          <path
            d="M388.853 661.64C389.047 661.779 390.062 660.417 391.897 659.597C393.059 659.208 394.243 658.883 395.441 658.624C394.822 658.352 394.148 658.232 393.474 658.273C392.799 658.314 392.144 658.516 391.563 658.86C389.478 659.833 388.644 661.64 388.853 661.64Z"
            fill="#263238"
          />
          <path
            d="M379.762 651.465C381.172 650.204 382.673 649.047 384.252 648.004C386.962 646.447 389.561 646.211 389.534 645.961C387.55 645.863 385.581 646.335 383.857 647.32C382.133 648.305 380.726 649.763 379.804 651.521L379.762 651.465Z"
            fill="#263238"
          />
          <path
            d="M377.899 642.68C378.038 642.889 379.943 641.749 382.584 641.29C385.225 640.832 387.407 641.165 387.462 640.929C385.859 640.33 384.123 640.177 382.439 640.485C380.756 640.794 379.187 641.552 377.899 642.68Z"
            fill="#263238"
          />
          <path
            d="M384.085 640.999C384.062 640.512 383.929 640.036 383.696 639.609C383.171 638.424 382.415 637.356 381.472 636.467C380.843 635.747 379.992 635.257 379.053 635.077C378.737 635.047 378.419 635.114 378.143 635.271C377.867 635.428 377.646 635.666 377.51 635.953C377.295 636.526 377.295 637.159 377.51 637.732C377.988 638.891 378.854 639.847 379.96 640.438C381.065 641.029 382.341 641.217 383.57 640.971C384.779 640.702 385.88 640.08 386.734 639.184C387.587 638.288 388.156 637.159 388.366 635.939C388.419 635.631 388.406 635.316 388.327 635.013C388.248 634.711 388.105 634.43 387.907 634.188C387.679 633.966 387.387 633.822 387.072 633.777C386.758 633.732 386.437 633.788 386.156 633.937C385.349 634.413 384.682 635.094 384.224 635.911C383.527 636.999 383.019 638.198 382.723 639.456C382.583 639.905 382.536 640.378 382.584 640.846C383.069 639.214 383.785 637.66 384.71 636.231C385.134 635.531 385.727 634.948 386.434 634.535C386.586 634.454 386.759 634.424 386.929 634.449C387.099 634.474 387.257 634.553 387.379 634.674C387.499 634.841 387.583 635.031 387.626 635.232C387.67 635.433 387.67 635.64 387.629 635.842C387.421 636.886 386.917 637.848 386.178 638.615C385.439 639.381 384.495 639.919 383.459 640.165C382.421 640.369 381.344 640.216 380.404 639.728C379.465 639.241 378.719 638.449 378.288 637.482C378.144 637.077 378.144 636.635 378.288 636.231C378.361 636.071 378.481 635.938 378.634 635.85C378.786 635.763 378.962 635.726 379.136 635.744C379.926 635.898 380.649 636.291 381.207 636.87C382.403 638.066 383.377 639.464 384.085 640.999Z"
            fill="#263238"
          />
          <path
            d="M319.2 598.061L319.756 642.639L295.681 641.721L292.108 598.59L319.2 598.061Z"
            fill="#FFBE9D"
          />
          <path
            d="M295.264 636.342C295.264 636.342 268.548 646.35 268.27 651.229L319.936 651.132V637.621L318.046 638.066C310.453 639.814 302.508 639.213 295.264 636.342Z"
            fill="#CFAAFF"
          />
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M314.001 640.164C314.484 640.33 314.891 640.664 315.147 641.106C315.402 641.548 315.489 642.068 315.391 642.569C315.259 643.073 314.939 643.506 314.497 643.781C314.054 644.055 313.524 644.149 313.014 644.043C312.486 643.884 312.037 643.533 311.754 643.06C311.471 642.587 311.375 642.025 311.485 641.485C311.679 640.976 312.062 640.562 312.554 640.329C313.046 640.096 313.609 640.062 314.126 640.234"
              fill="white"
            />
          </g>
          <g opacity="0.6">
            <path
              opacity="0.6"
              d="M319.922 651.132V646.962L270.244 648.616C270.244 648.616 267.95 649.616 268.214 651.243L319.922 651.132Z"
              fill="white"
            />
          </g>
          <path
            d="M294.416 636.078C294.416 636.328 295.695 636.439 296.932 637.246C298.169 638.052 298.836 639.122 299.072 639.011C299.309 638.9 298.933 637.454 297.404 636.509C295.875 635.564 294.36 635.842 294.416 636.078Z"
            fill="#263238"
          />
          <path
            d="M289.009 638.33C289.009 638.566 290.037 638.997 290.899 640.067C291.761 641.138 292.011 642.263 292.289 642.25C292.567 642.236 292.692 640.859 291.608 639.469C290.524 638.079 289.009 638.079 289.009 638.33Z"
            fill="#263238"
          />
          <path
            d="M285.937 644.821C286.173 644.821 286.521 643.612 285.867 642.236C285.214 640.86 284.033 640.303 283.935 640.512C283.838 640.72 284.561 641.457 285.075 642.597C285.589 643.737 285.687 644.793 285.937 644.821Z"
            fill="#263238"
          />
          <path
            d="M294.986 635.925C295.831 636.167 296.726 636.167 297.571 635.925C298.504 635.755 299.414 635.475 300.282 635.091C300.79 634.86 301.282 634.595 301.755 634.299C302.052 634.141 302.32 633.934 302.547 633.687C302.722 633.503 302.828 633.265 302.848 633.012C302.868 632.759 302.801 632.507 302.659 632.297C302.531 632.122 302.363 631.979 302.17 631.88C301.977 631.781 301.764 631.729 301.547 631.727C301.217 631.727 300.89 631.773 300.574 631.866C300.024 632.001 299.489 632.187 298.975 632.422C298.099 632.814 297.291 633.344 296.584 633.993C295.389 635.105 294.986 636.05 295.069 636.092C295.153 636.133 295.764 635.383 296.987 634.466C297.694 633.928 298.47 633.489 299.295 633.159C299.773 632.967 300.266 632.813 300.768 632.7C301.324 632.561 301.783 632.547 301.922 632.798C302.061 633.048 301.922 632.923 301.922 633.089C301.753 633.263 301.562 633.413 301.352 633.534C300.902 633.818 300.439 634.077 299.962 634.313C299.159 634.707 298.321 635.028 297.46 635.272C296.62 635.428 295.793 635.646 294.986 635.925Z"
            fill="#263238"
          />
          <path
            d="M295.306 636.286C295.403 636.286 295.903 635.425 295.987 633.784C296.025 632.845 295.908 631.906 295.639 631.004C295.467 630.461 295.243 629.935 294.972 629.434C294.831 629.093 294.605 628.793 294.316 628.564C294.026 628.336 293.682 628.185 293.318 628.127C293.087 628.133 292.863 628.204 292.671 628.332C292.48 628.46 292.328 628.64 292.234 628.85C292.108 629.153 292.019 629.471 291.97 629.795C291.854 630.569 291.892 631.358 292.083 632.117C292.274 632.875 292.613 633.589 293.082 634.215C294.096 635.605 295.153 635.842 295.167 635.758C295.18 635.675 294.374 635.147 293.596 633.868C293.152 633.125 292.872 632.295 292.776 631.435C292.707 630.937 292.707 630.432 292.776 629.934C292.859 629.392 293.068 628.947 293.276 629.003C293.485 629.058 293.971 629.406 294.18 629.851C294.434 630.294 294.648 630.759 294.819 631.241C295.113 632.069 295.286 632.935 295.333 633.812C295.445 635.3 295.18 636.245 295.306 636.286Z"
            fill="#263238"
          />
          <path
            d="M366.056 408.132C364.986 384.043 346.485 357.73 346.485 357.73L318.018 355.34V355.242H317.42L284.686 357.911C284.686 357.911 270.258 389.353 274.011 426.827C277.764 464.302 290.427 602.148 290.427 602.148H320.451L321.09 402.711L351.489 608.945H381.555C381.555 608.945 367.127 432.29 366.056 408.132Z"
            fill="#263238"
          />
          <path
            d="M286.771 598.784L290.955 619.106L321.535 619.009L323.384 600.285L286.771 598.784Z"
            fill="#455A64"
          />
          <path
            d="M349.474 605.276L356.174 628.183L384.641 624.207L383.154 598.451L349.474 605.276Z"
            fill="#455A64"
          />
          <path
            d="M322.313 402.53C322.313 402.53 322.16 402.53 321.868 402.53C321.441 402.44 321.039 402.26 320.687 402.002C319.475 400.954 318.673 399.511 318.421 397.929C317.726 394.051 317.031 388.7 316.42 382.764C315.808 376.829 315.349 371.45 315.03 367.558C314.891 365.598 314.793 364.027 314.752 362.929C314.679 362.366 314.679 361.796 314.752 361.233C314.899 361.783 314.992 362.347 315.03 362.915C315.155 364.138 315.335 365.695 315.53 367.502C315.947 371.547 316.489 376.843 317.087 382.695C317.685 388.547 318.31 393.996 318.88 397.818C319.074 399.318 319.753 400.714 320.812 401.793C321.268 402.12 321.776 402.369 322.313 402.53Z"
            fill="#455A64"
          />
          <path
            d="M319.769 359.801C319.769 359.801 320.006 359.621 320.492 359.551C321.187 359.481 321.886 359.642 322.48 360.01C322.94 360.289 323.335 360.663 323.639 361.106C323.943 361.549 324.15 362.052 324.245 362.581C324.37 363.234 324.256 363.91 323.926 364.486C323.572 365.122 323.006 365.613 322.327 365.876C321.699 366.14 321.006 366.205 320.34 366.063C319.674 365.922 319.068 365.58 318.602 365.083C318.263 364.659 318.03 364.161 317.922 363.63C317.813 363.098 317.832 362.548 317.976 362.025C318.143 361.333 318.516 360.707 319.047 360.232C319.436 359.927 319.7 359.857 319.714 359.885C319.091 360.494 318.661 361.272 318.477 362.123C318.385 362.564 318.394 363.021 318.502 363.459C318.61 363.897 318.816 364.304 319.102 364.653C319.49 365.02 319.976 365.268 320.501 365.366C321.026 365.465 321.568 365.41 322.063 365.209C322.568 365.046 322.999 364.71 323.279 364.259C323.559 363.809 323.67 363.273 323.592 362.748C323.521 362.3 323.361 361.87 323.123 361.483C322.884 361.097 322.571 360.761 322.202 360.496C321.468 360.051 320.628 359.811 319.769 359.801Z"
            fill="#455A64"
          />
          <path
            d="M304.41 317.532L301.505 303.395C301.451 303.13 301.457 302.856 301.523 302.594C301.588 302.331 301.711 302.087 301.883 301.878C302.054 301.669 302.27 301.501 302.515 301.386C302.76 301.271 303.027 301.212 303.298 301.213L339.869 301.394C340.124 301.394 340.376 301.448 340.609 301.553C340.842 301.657 341.051 301.809 341.221 301.999C341.392 302.189 341.52 302.413 341.598 302.656C341.677 302.899 341.703 303.155 341.676 303.409L337.895 335.796C337.823 336.414 337.524 336.983 337.057 337.394C336.59 337.805 335.987 338.028 335.365 338.02L311.513 337.839C310.955 337.833 310.414 337.643 309.974 337.299C309.535 336.955 309.221 336.475 309.08 335.935L304.41 317.532Z"
            fill="#455A64"
          />
          <path
            d="M301.143 310.095C301.92 310.092 302.68 310.324 303.322 310.761C303.965 311.197 304.46 311.818 304.744 312.542C305.341 314.098 306.217 315.933 307.19 316.461C308.983 317.434 312.083 318.227 313.723 317.42C315.363 316.614 321.882 310.748 323.022 311.402C324.162 312.055 325.149 313.515 324.009 314.821C322.869 316.128 316.823 320.048 317.963 321.354C319.102 322.661 331.863 316.92 333.809 317.42C335.755 317.921 336.908 319.13 335.768 319.922C334.629 320.715 325.969 323.967 325.482 325.107C325.482 325.107 338.535 321.688 339.035 322.494C339.535 323.3 340.342 324.621 339.035 325.107C337.728 325.594 326.525 328.54 325.482 329.027C323.861 329.707 322.169 330.201 320.437 330.5C320.089 330.5 317.532 331.362 315.183 332.168C312.537 333.088 309.646 333.014 307.051 331.96L305.661 331.418C304.658 331.006 303.809 330.292 303.231 329.376C302.653 328.459 302.375 327.385 302.436 326.303L302.714 321.855L301.324 318.171L301.143 310.095Z"
            fill="#FFBE9D"
          />
          <path
            d="M323.092 306.662C323.091 306.94 323.008 307.211 322.852 307.441C322.696 307.671 322.474 307.848 322.216 307.951C321.958 308.054 321.675 308.077 321.404 308.018C321.132 307.958 320.885 307.819 320.694 307.617C320.502 307.416 320.376 307.162 320.33 306.888C320.285 306.614 320.322 306.332 320.438 306.08C320.554 305.827 320.743 305.616 320.981 305.471C321.218 305.327 321.494 305.258 321.771 305.272C322.128 305.29 322.463 305.444 322.709 305.703C322.955 305.961 323.092 306.305 323.092 306.662Z"
            fill="#FAFAFA"
          />
          <path
            d="M35.6674 463.76L36.2234 463.329L37.9192 462.064L44.48 457.227L69.2498 439.157L155.166 376.815L155.041 377.204C147.582 356.336 143.764 334.341 143.754 312.18C143.748 304.867 144.138 297.559 144.921 290.288C145.733 283.238 146.921 276.236 148.48 269.312C151.394 256.203 155.719 243.447 161.379 231.268C177.641 196.599 203.814 167.529 236.592 147.729L242.847 144.143C244.668 143.114 246.266 142.364 247.531 141.71L250.422 140.32C251.076 139.987 251.423 139.848 251.423 139.848C251.423 139.848 251.103 140.042 250.45 140.39L247.67 141.905C246.419 142.586 244.821 143.295 243.014 144.393L236.814 148.035C204.256 167.982 178.265 197.042 162.06 231.616C156.432 243.756 152.135 256.47 149.244 269.535C147.69 276.416 146.512 283.376 145.714 290.385C144.93 297.628 144.541 304.909 144.546 312.194C144.531 334.263 148.297 356.171 155.68 376.968L155.763 377.218L155.555 377.371L69.5 439.504L44.619 457.407C41.7139 459.464 39.4899 461.063 37.9609 462.161L36.2373 463.37L35.6674 463.76Z"
            fill="#FF725E"
          />
          <path
            d="M195.49 100.942C195.49 100.942 195.226 101.095 194.697 101.359L192.362 102.499L188.567 104.347C187.052 105.07 185.398 106.071 183.48 107.127C172.769 113.094 162.72 120.176 153.498 128.255C147.143 133.843 141.163 139.842 135.594 146.214C129.253 153.541 123.424 161.297 118.15 169.427C106.109 188.181 96.9318 208.626 90.9199 230.087C84.8919 251.536 81.3336 273.604 80.3142 295.861C79.36 314.007 79.7782 332.198 81.5652 350.28C82.718 362.532 85.0881 374.638 88.6403 386.42C89.933 390.59 91.1145 393.731 91.9346 395.858L92.8937 398.277L93.1995 399.111C93.1995 399.111 93.0605 398.847 92.8242 398.304L91.74 395.844C90.8782 393.731 89.655 390.576 88.3206 386.434C84.6566 374.701 82.202 362.624 80.9953 350.391C79.1153 332.269 78.6274 314.03 79.5358 295.834C80.5102 273.519 84.0597 251.392 90.1137 229.892C96.1472 208.366 105.368 187.863 117.469 169.066C122.798 160.911 128.687 153.136 135.094 145.797C140.71 139.414 146.747 133.414 153.164 127.838C162.441 119.774 172.564 112.736 183.355 106.849C185.273 105.835 186.941 104.862 188.47 104.167L192.307 102.373L194.669 101.289L195.49 100.942Z"
            fill="#FF725E"
          />
          <path
            d="M240.567 43.09C240.567 43.09 240.637 43.1595 240.748 43.3263L241.234 44.0352L243.041 46.8152L249.783 57.4626C255.538 66.6644 263.683 79.7026 273.232 94.8953L272.857 94.7563C285.951 91.1702 299.316 88.6599 312.819 87.2503C325.995 85.854 339.259 85.4961 352.49 86.18C363.294 86.7575 374.049 88.0484 384.682 90.0442L388.005 90.6558L391.132 91.3369L396.873 92.6157C400.417 93.547 403.475 94.2837 405.963 94.9926L411.648 96.7162L413.122 97.1888C413.291 97.2297 413.454 97.2903 413.608 97.3695C413.434 97.3469 413.262 97.3097 413.094 97.2583L411.607 96.8552L405.88 95.2706C403.392 94.6034 400.32 93.8806 396.776 93.0049L391.035 91.74L387.907 91.0867L384.585 90.5029C373.969 88.5727 363.239 87.333 352.462 86.7916C339.263 86.1324 326.032 86.4995 312.889 87.8897C299.427 89.2875 286.1 91.7698 273.038 95.3123H272.787L272.662 95.1038C263.196 79.8138 255.148 66.7617 249.435 57.5738C246.655 52.9868 244.404 49.345 242.861 46.8152L241.137 43.9518L240.706 43.2012C240.659 43.1656 240.612 43.1286 240.567 43.09Z"
            fill="#FF725E"
          />
          <path
            d="M593.447 229.211C593.376 229.09 593.325 228.958 593.294 228.822L592.918 227.64C592.585 226.556 592.126 225.055 591.528 223.123C590.931 221.191 590.264 218.744 589.277 216.006L587.775 211.586C587.497 210.807 587.233 210.001 586.941 209.181L585.968 206.679C584.578 203.274 583.188 199.507 581.409 195.559C580.506 193.599 579.672 191.528 578.629 189.471L575.46 183.188C570.571 173.887 565.035 164.941 558.891 156.417C552.653 147.96 545.854 139.931 538.542 132.384C536.832 130.66 535.164 128.992 533.552 127.352C531.939 125.712 530.243 124.266 528.673 122.793C525.559 119.804 522.459 117.233 519.693 114.856L517.65 113.104L515.69 111.52L512.048 108.601C509.796 106.752 507.767 105.279 506.127 104.041L502.36 101.261L501.387 100.511L501.067 100.233C501.067 100.233 501.206 100.233 501.429 100.441L502.443 101.15L506.294 103.93C507.962 105.126 510.019 106.571 512.298 108.392L515.982 111.2L517.997 112.771L520.041 114.508C522.821 116.885 525.976 119.415 529.104 122.389C530.688 123.863 532.37 125.336 534.024 126.935L539.042 131.953C546.406 139.499 553.243 147.543 559.503 156.027C565.671 164.579 571.209 173.568 576.072 182.924L579.241 189.29C580.256 191.347 581.09 193.46 582.021 195.392C583.842 199.326 585.19 203.107 586.511 206.512C586.844 207.374 587.164 208.208 587.47 209.028C587.775 209.848 588.012 210.668 588.276 211.447C588.79 213.017 589.277 214.505 589.735 215.881C590.694 218.661 591.334 221.079 591.876 223.053C592.418 225.027 592.821 226.528 593.113 227.626C593.238 228.113 593.335 228.502 593.419 228.808L593.447 229.211Z"
            fill="#FF725E"
          />
          <path
            d="M541.224 615.047C541.224 615.047 541.141 614.922 541.002 614.658L540.418 613.49C539.89 612.42 539.153 610.891 538.194 608.917C536.248 604.872 533.454 599.034 529.951 591.709C522.932 576.961 513.077 556.208 501.623 532.092L501.498 531.842L501.734 531.661C512.452 523.548 522.548 514.646 531.939 505.029C533.579 503.43 535.108 501.762 536.623 500.094L541.169 495.146L545.45 490.114C546.84 488.432 548.23 486.806 549.62 485.096C552.247 481.691 554.93 478.41 557.293 475.019L560.879 470.028C562.019 468.36 563.103 466.679 564.201 465.038C571.937 453.359 578.759 441.1 584.606 428.37C587.164 422.963 589.138 417.917 590.931 413.525C592.724 409.133 593.989 405.185 595.101 402.071C596.213 398.958 596.908 396.442 597.45 394.732C597.728 393.926 597.936 393.342 598.089 392.828C598.156 392.611 598.235 392.397 598.326 392.188C598.326 392.188 598.325 392.411 598.159 392.856C597.992 393.3 597.839 393.954 597.589 394.774C597.075 396.497 596.324 398.944 595.365 402.155C594.406 405.366 592.946 409.202 591.306 413.664C589.666 418.126 587.595 423.158 585.065 428.579C579.261 441.368 572.467 453.684 564.743 465.414C563.645 467.054 562.575 468.75 561.421 470.418L557.835 475.422C555.472 478.841 552.789 482.121 550.148 485.527C548.855 487.251 547.368 488.891 545.978 490.573C544.588 492.255 543.129 493.923 541.697 495.632L537.138 500.581C535.623 502.249 534.094 503.931 532.453 505.529C523.027 515.173 512.889 524.094 502.124 532.217L502.235 531.8L530.327 591.57L538.389 608.82C539.306 610.822 540.029 612.364 540.515 613.449L541.057 614.658L541.224 615.047Z"
            fill="#FF725E"
          />
          <g opacity="0.8">
            <path
              opacity="0.8"
              d="M35.6674 463.76L36.2234 463.329L37.9192 462.064L44.48 457.227L69.2498 439.157L155.166 376.815L155.041 377.204C147.582 356.336 143.764 334.341 143.754 312.18C143.748 304.867 144.138 297.559 144.921 290.288C145.733 283.238 146.921 276.236 148.48 269.312C151.394 256.203 155.719 243.447 161.379 231.268C177.641 196.599 203.814 167.529 236.592 147.729L242.847 144.143C244.668 143.114 246.266 142.364 247.531 141.71L250.422 140.32C251.076 139.987 251.423 139.848 251.423 139.848C251.423 139.848 251.103 140.042 250.45 140.39L247.67 141.905C246.419 142.586 244.821 143.295 243.014 144.393L236.814 148.035C204.256 167.982 178.265 197.042 162.06 231.616C156.432 243.756 152.135 256.47 149.244 269.535C147.69 276.416 146.512 283.376 145.714 290.385C144.93 297.628 144.541 304.909 144.546 312.194C144.531 334.263 148.297 356.171 155.68 376.968L155.763 377.218L155.555 377.371L69.5 439.504L44.619 457.407C41.7139 459.464 39.4899 461.063 37.9609 462.161L36.2373 463.37L35.6674 463.76Z"
              fill="white"
            />
            <path
              opacity="0.8"
              d="M195.49 100.942C195.49 100.942 195.226 101.095 194.697 101.359L192.362 102.499L188.567 104.347C187.052 105.07 185.398 106.071 183.48 107.127C172.769 113.094 162.72 120.176 153.498 128.255C147.143 133.843 141.163 139.842 135.594 146.214C129.253 153.541 123.424 161.297 118.15 169.427C106.109 188.181 96.9318 208.626 90.9199 230.087C84.8919 251.536 81.3336 273.604 80.3142 295.861C79.36 314.007 79.7782 332.198 81.5652 350.28C82.718 362.532 85.0881 374.638 88.6403 386.42C89.933 390.59 91.1145 393.731 91.9346 395.858L92.8937 398.277L93.1995 399.111C93.1995 399.111 93.0605 398.847 92.8242 398.304L91.74 395.844C90.8782 393.731 89.655 390.576 88.3206 386.434C84.6566 374.701 82.202 362.624 80.9953 350.391C79.1153 332.269 78.6274 314.03 79.5358 295.834C80.5102 273.519 84.0597 251.392 90.1137 229.892C96.1472 208.366 105.368 187.863 117.469 169.066C122.798 160.911 128.687 153.136 135.094 145.797C140.71 139.414 146.747 133.414 153.164 127.838C162.441 119.774 172.564 112.736 183.355 106.849C185.273 105.835 186.941 104.862 188.47 104.167L192.307 102.373L194.669 101.289L195.49 100.942Z"
              fill="white"
            />
            <path
              opacity="0.8"
              d="M240.567 43.09C240.567 43.09 240.637 43.1595 240.748 43.3263L241.234 44.0352L243.041 46.8152L249.783 57.4626C255.538 66.6644 263.683 79.7026 273.232 94.8953L272.857 94.7563C285.951 91.1702 299.316 88.6599 312.819 87.2503C325.995 85.854 339.259 85.4961 352.49 86.18C363.294 86.7575 374.049 88.0484 384.682 90.0442L388.005 90.6558L391.132 91.3369L396.873 92.6157C400.417 93.547 403.475 94.2837 405.963 94.9926L411.648 96.7162L413.122 97.1888C413.291 97.2297 413.454 97.2903 413.608 97.3695C413.434 97.3469 413.262 97.3097 413.094 97.2583L411.607 96.8552L405.88 95.2706C403.392 94.6034 400.32 93.8806 396.776 93.0049L391.035 91.74L387.907 91.0867L384.585 90.5029C373.969 88.5727 363.239 87.333 352.462 86.7916C339.263 86.1324 326.032 86.4995 312.889 87.8897C299.427 89.2875 286.1 91.7698 273.038 95.3123H272.787L272.662 95.1038C263.196 79.8138 255.148 66.7617 249.435 57.5738C246.655 52.9868 244.404 49.345 242.861 46.8152L241.137 43.9518L240.706 43.2012C240.659 43.1656 240.612 43.1286 240.567 43.09Z"
              fill="white"
            />
            <path
              opacity="0.8"
              d="M593.447 229.211C593.376 229.09 593.325 228.958 593.294 228.822L592.918 227.64C592.585 226.556 592.126 225.055 591.528 223.123C590.931 221.191 590.263 218.744 589.277 216.006L587.775 211.586C587.497 210.807 587.233 210.001 586.941 209.181L585.968 206.679C584.578 203.274 583.188 199.507 581.409 195.559C580.506 193.599 579.672 191.528 578.629 189.471L575.46 183.188C570.571 173.887 565.035 164.941 558.891 156.417C552.653 147.96 545.854 139.931 538.542 132.384C536.832 130.66 535.164 128.992 533.552 127.352C531.939 125.712 530.243 124.266 528.673 122.793C525.559 119.804 522.459 117.233 519.693 114.856L517.65 113.104L515.69 111.52L512.048 108.601C509.796 106.752 507.767 105.279 506.127 104.041L502.36 101.261L501.387 100.511L501.067 100.233C501.067 100.233 501.206 100.233 501.429 100.441L502.443 101.15L506.294 103.93C507.962 105.126 510.019 106.571 512.298 108.392L515.982 111.2L517.997 112.771L520.041 114.508C522.821 116.885 525.976 119.415 529.103 122.389C530.688 123.863 532.37 125.336 534.024 126.935L539.042 131.953C546.406 139.499 553.243 147.543 559.503 156.027C565.671 164.579 571.209 173.568 576.072 182.924L579.241 189.29C580.255 191.347 581.089 193.46 582.021 195.392C583.842 199.326 585.19 203.107 586.511 206.512C586.844 207.374 587.164 208.208 587.47 209.028C587.775 209.848 588.012 210.668 588.276 211.447C588.79 213.017 589.277 214.505 589.735 215.881C590.694 218.661 591.334 221.079 591.876 223.053C592.418 225.027 592.821 226.528 593.113 227.626C593.238 228.113 593.335 228.502 593.419 228.808L593.447 229.211Z"
              fill="white"
            />
            <path
              opacity="0.8"
              d="M541.224 615.047C541.224 615.047 541.141 614.922 541.002 614.658L540.418 613.49C539.89 612.42 539.153 610.891 538.194 608.917C536.248 604.872 533.454 599.034 529.951 591.709C522.932 576.961 513.077 556.208 501.623 532.092L501.498 531.842L501.734 531.661C512.452 523.548 522.548 514.646 531.939 505.029C533.579 503.43 535.108 501.762 536.623 500.094L541.169 495.146L545.45 490.114C546.84 488.432 548.23 486.806 549.62 485.096C552.247 481.691 554.93 478.41 557.293 475.019L560.879 470.028C562.019 468.36 563.103 466.679 564.201 465.038C571.937 453.359 578.759 441.1 584.606 428.37C587.164 422.963 589.138 417.917 590.931 413.525C592.724 409.133 593.989 405.185 595.101 402.071C596.213 398.958 596.908 396.442 597.45 394.732C597.728 393.926 597.936 393.342 598.089 392.828C598.156 392.611 598.235 392.397 598.326 392.188C598.326 392.188 598.325 392.411 598.159 392.856C597.992 393.3 597.839 393.954 597.589 394.774C597.075 396.497 596.324 398.944 595.365 402.155C594.406 405.366 592.946 409.202 591.306 413.664C589.666 418.126 587.595 423.158 585.065 428.579C579.261 441.368 572.467 453.684 564.743 465.414C563.645 467.054 562.575 468.75 561.421 470.418L557.835 475.422C555.472 478.841 552.789 482.121 550.148 485.527C548.855 487.251 547.368 488.891 545.978 490.573C544.588 492.255 543.129 493.923 541.697 495.632L537.138 500.581C535.623 502.249 534.094 503.931 532.453 505.529C523.027 515.173 512.889 524.094 502.124 532.217L502.235 531.8L530.327 591.57L538.389 608.82C539.306 610.822 540.029 612.364 540.515 613.449L541.057 614.658L541.224 615.047Z"
              fill="white"
            />
          </g>
          <path
            d="M450.471 216.562C453.765 216.562 456.434 213.892 456.434 210.599C456.434 207.306 453.765 204.636 450.471 204.636C447.178 204.636 444.508 207.306 444.508 210.599C444.508 213.892 447.178 216.562 450.471 216.562Z"
            fill="#FF725E"
          />
          <path
            d="M420.364 97.4945C420.364 98.6773 420.013 99.8334 419.356 100.817C418.698 101.8 417.764 102.566 416.671 103.018C415.578 103.47 414.375 103.587 413.216 103.356C412.056 103.124 410.991 102.553 410.155 101.716C409.32 100.879 408.752 99.8124 408.523 98.652C408.294 97.4917 408.414 96.2895 408.869 95.1976C409.323 94.1057 410.092 93.1732 411.076 92.5181C412.061 91.863 413.218 91.5148 414.401 91.5175C415.185 91.5175 415.962 91.6722 416.686 91.9728C417.41 92.2733 418.068 92.7138 418.622 93.269C419.176 93.8242 419.615 94.4832 419.914 95.2083C420.213 95.9334 420.366 96.7103 420.364 97.4945Z"
            fill="#FF725E"
          />
          <path
            d="M598.854 229.447C598.856 230.631 598.508 231.788 597.852 232.773C597.197 233.758 596.263 234.526 595.17 234.981C594.078 235.435 592.875 235.554 591.714 235.324C590.553 235.094 589.487 234.524 588.65 233.688C587.814 232.851 587.244 231.785 587.014 230.624C586.784 229.463 586.903 228.26 587.357 227.167C587.811 226.075 588.58 225.141 589.565 224.486C590.55 223.83 591.707 223.481 592.891 223.484C594.472 223.484 595.989 224.112 597.107 225.231C598.225 226.349 598.854 227.866 598.854 229.447Z"
            fill="#FF725E"
          />
          <path
            d="M290.371 508.378C290.368 509.557 290.016 510.709 289.359 511.688C288.702 512.666 287.77 513.429 286.68 513.878C285.59 514.327 284.392 514.443 283.236 514.211C282.08 513.98 281.019 513.411 280.186 512.576C279.354 511.742 278.787 510.679 278.558 509.523C278.329 508.366 278.448 507.168 278.9 506.079C279.352 504.991 280.116 504.06 281.096 503.405C282.077 502.751 283.229 502.401 284.408 502.401C285.192 502.401 285.969 502.556 286.693 502.857C287.418 503.157 288.075 503.598 288.629 504.153C289.183 504.708 289.622 505.367 289.921 506.092C290.22 506.817 290.373 507.594 290.371 508.378Z"
            fill="#FF725E"
          />
          <path
            d="M208.166 510.019C211.46 510.019 214.13 507.349 214.13 504.056C214.13 500.762 211.46 498.093 208.166 498.093C204.873 498.093 202.203 500.762 202.203 504.056C202.203 507.349 204.873 510.019 208.166 510.019Z"
            fill="#FF725E"
          />
          <path
            d="M535.247 384.446C538.541 384.446 541.21 381.776 541.21 378.483C541.21 375.19 538.541 372.52 535.247 372.52C531.954 372.52 529.284 375.19 529.284 378.483C529.284 381.776 531.954 384.446 535.247 384.446Z"
            fill="#FF725E"
          />
          <path
            d="M93.8667 232.533C93.8667 233.712 93.517 234.865 92.8617 235.846C92.2065 236.827 91.2752 237.591 90.1856 238.042C89.096 238.494 87.897 238.612 86.7402 238.382C85.5835 238.151 84.521 237.584 83.687 236.75C82.8531 235.916 82.2852 234.853 82.0551 233.696C81.825 232.54 81.9431 231.341 82.3944 230.251C82.8457 229.161 83.61 228.23 84.5907 227.575C85.5713 226.92 86.7242 226.57 87.9036 226.57C89.4851 226.57 91.0018 227.198 92.1201 228.317C93.2384 229.435 93.8667 230.952 93.8667 232.533Z"
            fill="#FF725E"
          />
          <path
            d="M428.704 280.224C428.701 281.403 428.349 282.554 427.692 283.533C427.035 284.512 426.103 285.274 425.013 285.723C423.923 286.172 422.725 286.289 421.569 286.057C420.413 285.825 419.352 285.256 418.519 284.422C417.687 283.587 417.12 282.525 416.891 281.368C416.662 280.212 416.781 279.014 417.233 277.925C417.685 276.836 418.449 275.906 419.429 275.251C420.41 274.596 421.562 274.247 422.741 274.247C423.525 274.247 424.301 274.402 425.026 274.702C425.75 275.003 426.408 275.443 426.962 275.998C427.516 276.554 427.955 277.213 428.254 277.938C428.553 278.663 428.706 279.44 428.704 280.224Z"
            fill="#FF725E"
          />
          <path
            d="M544.046 283.755C547.339 283.755 550.009 281.085 550.009 277.791C550.009 274.498 547.339 271.828 544.046 271.828C540.753 271.828 538.083 274.498 538.083 277.791C538.083 281.085 540.753 283.755 544.046 283.755Z"
            fill="#FF725E"
          />
          <path
            d="M158.571 255.065C158.571 256.244 158.221 257.397 157.566 258.378C156.911 259.358 155.98 260.123 154.89 260.574C153.8 261.025 152.602 261.144 151.445 260.913C150.288 260.683 149.226 260.115 148.392 259.281C147.558 258.448 146.99 257.385 146.76 256.228C146.53 255.072 146.648 253.873 147.099 252.783C147.55 251.693 148.315 250.762 149.295 250.107C150.276 249.452 151.429 249.102 152.608 249.102C154.189 249.105 155.703 249.735 156.821 250.852C157.938 251.97 158.568 253.485 158.571 255.065Z"
            fill="#FF725E"
          />
          <path
            d="M157.265 182.034C160.558 182.034 163.228 179.365 163.228 176.071C163.228 172.778 160.558 170.108 157.265 170.108C153.971 170.108 151.302 172.778 151.302 176.071C151.302 179.365 153.971 182.034 157.265 182.034Z"
            fill="#FF725E"
          />
          <path
            d="M96.188 402.03C96.188 402.621 96.0128 403.199 95.6844 403.69C95.356 404.181 94.8893 404.565 94.3432 404.791C93.7971 405.017 93.1962 405.076 92.6165 404.961C92.0368 404.845 91.5043 404.561 91.0864 404.143C90.6684 403.725 90.3838 403.192 90.2685 402.613C90.1532 402.033 90.2124 401.432 90.4385 400.886C90.6647 400.34 91.0478 399.873 91.5392 399.545C92.0307 399.216 92.6085 399.041 93.1996 399.041C93.5925 399.039 93.982 399.115 94.3454 399.265C94.7088 399.414 95.039 399.635 95.3168 399.912C95.5947 400.19 95.8148 400.52 95.9643 400.884C96.1139 401.247 96.1899 401.637 96.188 402.03Z"
            fill="#FF725E"
          />
          <path
            d="M146.645 315.53C146.645 316.121 146.47 316.699 146.141 317.19C145.813 317.682 145.346 318.065 144.8 318.291C144.254 318.517 143.653 318.576 143.073 318.461C142.494 318.346 141.961 318.061 141.543 317.643C141.125 317.225 140.841 316.693 140.725 316.113C140.61 315.533 140.669 314.932 140.895 314.386C141.122 313.84 141.505 313.374 141.996 313.045C142.488 312.717 143.065 312.542 143.656 312.542C144.049 312.54 144.439 312.616 144.802 312.765C145.166 312.915 145.496 313.135 145.774 313.413C146.052 313.691 146.272 314.021 146.421 314.384C146.571 314.748 146.647 315.137 146.645 315.53Z"
            fill="#FF725E"
          />
          <path
            d="M204.511 420.1C204.511 420.691 204.335 421.269 204.007 421.76C203.679 422.252 203.212 422.635 202.666 422.861C202.12 423.087 201.519 423.146 200.939 423.031C200.359 422.915 199.827 422.631 199.409 422.213C198.991 421.795 198.706 421.262 198.591 420.683C198.476 420.103 198.535 419.502 198.761 418.956C198.987 418.41 199.37 417.943 199.862 417.615C200.353 417.287 200.931 417.111 201.522 417.111C201.915 417.109 202.305 417.185 202.668 417.335C203.031 417.485 203.362 417.705 203.639 417.982C203.917 418.26 204.137 418.591 204.287 418.954C204.436 419.317 204.513 419.707 204.511 420.1Z"
            fill="#FF725E"
          />
          <path
            d="M174.056 314.14C174.056 314.731 173.88 315.309 173.552 315.8C173.224 316.292 172.757 316.675 172.211 316.901C171.665 317.127 171.064 317.186 170.484 317.071C169.905 316.956 169.372 316.671 168.954 316.253C168.536 315.835 168.251 315.303 168.136 314.723C168.021 314.143 168.08 313.542 168.306 312.996C168.532 312.45 168.915 311.984 169.407 311.655C169.898 311.327 170.476 311.152 171.067 311.152C171.46 311.15 171.85 311.226 172.213 311.375C172.576 311.525 172.907 311.745 173.185 312.023C173.462 312.301 173.682 312.631 173.832 312.994C173.982 313.358 174.058 313.747 174.056 314.14Z"
            fill="#FF725E"
          />
          <path
            d="M216.701 247.003C216.704 247.595 216.531 248.174 216.204 248.667C215.877 249.16 215.412 249.546 214.866 249.774C214.32 250.002 213.719 250.063 213.138 249.95C212.557 249.836 212.024 249.552 211.604 249.135C211.185 248.718 210.899 248.185 210.782 247.605C210.666 247.025 210.724 246.423 210.95 245.877C211.176 245.33 211.559 244.862 212.051 244.533C212.542 244.204 213.121 244.028 213.712 244.028C214.503 244.028 215.261 244.341 215.821 244.899C216.381 245.456 216.697 246.213 216.701 247.003Z"
            fill="#FF725E"
          />
          <path
            d="M110.686 318.491C112.336 318.491 113.674 317.153 113.674 315.502C113.674 313.852 112.336 312.514 110.686 312.514C109.035 312.514 107.697 313.852 107.697 315.502C107.697 317.153 109.035 318.491 110.686 318.491Z"
            fill="#FF725E"
          />
          <path
            d="M511.298 433.18C511.298 433.771 511.122 434.348 510.794 434.84C510.466 435.331 509.999 435.714 509.453 435.941C508.907 436.167 508.306 436.226 507.726 436.111C507.146 435.995 506.614 435.711 506.196 435.293C505.778 434.875 505.493 434.342 505.378 433.763C505.263 433.183 505.322 432.582 505.548 432.036C505.774 431.49 506.157 431.023 506.649 430.695C507.14 430.366 507.718 430.191 508.309 430.191C509.102 430.191 509.862 430.506 510.422 431.066C510.983 431.627 511.298 432.387 511.298 433.18Z"
            fill="#FF725E"
          />
          <path
            d="M490.489 417.556C490.489 418.148 490.314 418.726 489.985 419.218C489.656 419.709 489.188 420.092 488.641 420.318C488.094 420.544 487.493 420.602 486.913 420.486C486.333 420.37 485.8 420.084 485.383 419.664C484.965 419.245 484.682 418.711 484.568 418.13C484.454 417.55 484.516 416.948 484.744 416.403C484.972 415.857 485.357 415.391 485.851 415.064C486.344 414.738 486.923 414.565 487.515 414.567C487.907 414.567 488.294 414.645 488.656 414.795C489.018 414.946 489.347 415.166 489.623 415.444C489.899 415.721 490.118 416.051 490.267 416.413C490.416 416.776 490.491 417.164 490.489 417.556Z"
            fill="#FF725E"
          />
          <path
            d="M533.621 447.26C533.621 447.851 533.446 448.429 533.117 448.921C532.789 449.412 532.322 449.795 531.776 450.021C531.23 450.247 530.629 450.307 530.049 450.191C529.47 450.076 528.937 449.791 528.519 449.373C528.101 448.955 527.817 448.423 527.701 447.843C527.586 447.264 527.645 446.663 527.872 446.117C528.098 445.571 528.481 445.104 528.972 444.775C529.464 444.447 530.041 444.272 530.632 444.272C531.025 444.27 531.415 444.346 531.778 444.496C532.142 444.645 532.472 444.865 532.75 445.143C533.028 445.421 533.248 445.751 533.397 446.114C533.547 446.478 533.623 446.867 533.621 447.26Z"
            fill="#FF725E"
          />
          <path
            d="M416.138 450.054C416.138 450.645 415.963 451.223 415.635 451.714C415.306 452.206 414.839 452.589 414.293 452.815C413.747 453.041 413.146 453.1 412.567 452.985C411.987 452.87 411.454 452.585 411.036 452.167C410.619 451.749 410.334 451.217 410.219 450.637C410.103 450.057 410.162 449.457 410.389 448.911C410.615 448.364 410.998 447.898 411.489 447.569C411.981 447.241 412.559 447.066 413.15 447.066C413.942 447.066 414.702 447.381 415.263 447.941C415.823 448.501 416.138 449.262 416.138 450.054Z"
            fill="#FF725E"
          />
          <path
            d="M435.07 495.924C435.07 496.516 434.894 497.094 434.565 497.586C434.236 498.078 433.769 498.461 433.222 498.686C432.675 498.912 432.073 498.971 431.493 498.854C430.913 498.738 430.381 498.452 429.963 498.032C429.546 497.613 429.262 497.079 429.149 496.499C429.035 495.918 429.096 495.317 429.325 494.771C429.553 494.225 429.938 493.759 430.431 493.432C430.925 493.106 431.504 492.933 432.095 492.936C432.886 492.939 433.642 493.256 434.2 493.816C434.757 494.376 435.07 495.134 435.07 495.924Z"
            fill="#FF725E"
          />
          <path
            d="M401.932 482.052C401.935 482.644 401.762 483.224 401.434 483.717C401.107 484.211 400.641 484.596 400.094 484.824C399.547 485.052 398.945 485.112 398.364 484.998C397.783 484.883 397.25 484.598 396.831 484.179C396.412 483.76 396.127 483.226 396.012 482.645C395.897 482.064 395.958 481.462 396.186 480.916C396.413 480.369 396.799 479.903 397.292 479.575C397.786 479.248 398.366 479.075 398.958 479.077C399.747 479.077 400.503 479.391 401.061 479.949C401.619 480.507 401.932 481.263 401.932 482.052Z"
            fill="#FF725E"
          />
          <path
            d="M208.875 213.573C208.878 214.165 208.705 214.744 208.378 215.238C208.052 215.731 207.586 216.116 207.04 216.344C206.494 216.573 205.893 216.634 205.312 216.52C204.732 216.406 204.198 216.123 203.779 215.705C203.359 215.288 203.073 214.756 202.957 214.176C202.84 213.595 202.899 212.994 203.124 212.447C203.35 211.9 203.733 211.433 204.225 211.104C204.717 210.775 205.295 210.599 205.887 210.599C206.677 210.599 207.435 210.912 207.995 211.469C208.555 212.027 208.872 212.783 208.875 213.573Z"
            fill="#FF725E"
          />
          <path
            d="M279.39 178.907C279.393 179.499 279.22 180.078 278.893 180.571C278.566 181.064 278.101 181.449 277.555 181.678C277.009 181.906 276.408 181.967 275.827 181.854C275.246 181.74 274.713 181.456 274.293 181.039C273.874 180.621 273.588 180.089 273.471 179.509C273.355 178.929 273.413 178.327 273.639 177.78C273.865 177.233 274.248 176.766 274.74 176.437C275.231 176.108 275.81 175.932 276.401 175.932C277.192 175.932 277.95 176.245 278.51 176.803C279.07 177.36 279.386 178.117 279.39 178.907Z"
            fill="#FF725E"
          />
          <path
            d="M366.668 170.706C366.668 171.297 366.493 171.875 366.164 172.366C365.836 172.858 365.369 173.241 364.823 173.467C364.277 173.693 363.676 173.752 363.097 173.637C362.517 173.522 361.984 173.237 361.566 172.819C361.148 172.401 360.864 171.869 360.749 171.289C360.633 170.709 360.692 170.108 360.919 169.562C361.145 169.016 361.528 168.549 362.019 168.221C362.511 167.893 363.089 167.717 363.68 167.717C364.472 167.717 365.232 168.032 365.793 168.593C366.353 169.153 366.668 169.913 366.668 170.706Z"
            fill="#FF725E"
          />
          <path
            d="M503.986 99.1487C503.989 99.7403 503.816 100.319 503.489 100.813C503.163 101.306 502.697 101.691 502.151 101.92C501.605 102.148 501.004 102.209 500.423 102.095C499.843 101.982 499.309 101.698 498.89 101.281C498.47 100.863 498.184 100.331 498.068 99.7507C497.951 99.1706 498.01 98.569 498.235 98.0221C498.461 97.4753 498.844 97.0077 499.336 96.6787C499.828 96.3497 500.406 96.1741 500.998 96.1741C501.788 96.1741 502.546 96.487 503.106 97.0445C503.666 97.6019 503.982 98.3585 503.986 99.1487Z"
            fill="#FF725E"
          />
          <path
            d="M274.692 94.2976C274.692 94.8886 274.516 95.4664 274.188 95.9579C273.86 96.4493 273.393 96.8324 272.847 97.0586C272.301 97.2848 271.7 97.344 271.12 97.2286C270.54 97.1133 270.008 96.8287 269.59 96.4108C269.172 95.9928 268.887 95.4603 268.772 94.8806C268.657 94.3009 268.716 93.7 268.942 93.1539C269.168 92.6078 269.551 92.1411 270.043 91.8127C270.534 91.4843 271.112 91.3091 271.703 91.3091C272.495 91.3127 273.253 91.6288 273.812 92.1884C274.372 92.7481 274.688 93.5061 274.692 94.2976Z"
            fill="#FF725E"
          />
          <path
            d="M240.567 422.727C240.567 422.727 240.442 422.616 240.22 422.379L239.233 421.337L235.522 417.264C234.715 416.361 233.784 415.401 232.867 414.276L229.878 410.578C228.822 409.188 227.64 407.868 226.487 406.311L222.928 401.335C217.127 393.117 212.137 384.356 208.027 375.175C203.031 363.903 199.54 352.023 197.644 339.841C197.032 336.616 196.824 333.378 196.449 330.208L196.157 325.468C196.157 324.69 196.06 323.898 196.018 323.133C195.976 322.369 196.018 321.576 196.018 320.798C196.018 319.26 196.018 317.731 196.018 316.211L196.185 311.707C196.185 310.22 196.324 308.747 196.449 307.301C196.574 305.856 196.699 304.424 196.81 303.006C197.891 293.006 199.949 283.137 202.954 273.538C204.956 267.067 207.423 260.75 210.335 254.634C211.377 252.466 212.253 250.825 212.878 249.727C213.184 249.171 213.434 248.768 213.601 248.49C213.768 248.212 213.865 248.073 213.879 248.087C213.82 248.235 213.75 248.379 213.671 248.518L213.004 249.797C212.42 250.909 211.614 252.577 210.599 254.745C207.786 260.891 205.394 267.221 203.44 273.691C200.491 283.252 198.462 293.073 197.38 303.02C197.269 304.41 197.144 305.8 197.032 307.301C196.921 308.802 196.81 310.206 196.782 311.68C196.755 313.153 196.671 314.654 196.615 316.155C196.56 317.657 196.615 319.2 196.615 320.729C196.615 321.493 196.615 322.271 196.615 323.05C196.615 323.828 196.713 324.593 196.754 325.371L197.046 330.097C197.436 333.252 197.644 336.463 198.242 339.674C200.131 351.797 203.589 363.623 208.528 374.855C212.597 384.009 217.521 392.758 223.234 400.987L226.737 405.977C227.877 407.534 229.044 408.938 230.087 410.272L233.02 413.998C233.923 415.137 234.84 416.11 235.619 417.014L239.233 421.184L240.164 422.282C240.47 422.56 240.581 422.713 240.567 422.727Z"
            fill="#FF725E"
          />
          <path
            d="M110.602 322.925C110.587 322.791 110.587 322.656 110.602 322.522V321.34C110.602 320.242 110.602 318.713 110.7 316.781C110.7 315.794 110.7 314.682 110.783 313.487C110.866 312.291 110.936 310.985 111.033 309.581C111.228 306.801 111.367 303.576 111.784 300.059C111.978 298.308 112.173 296.459 112.368 294.499C112.562 292.539 112.91 290.593 113.202 288.536C113.716 284.366 114.592 280.043 115.398 275.442C119.309 255.498 125.483 236.065 133.801 217.521C135.789 213.281 137.61 209.181 139.57 205.553C140.529 203.704 141.405 201.897 142.35 200.215L145.13 195.364C146.812 192.251 148.549 189.554 149.995 187.149C150.746 185.954 151.385 184.828 152.066 183.813L153.887 181.033L156.445 177.253L157.112 176.28C157.189 176.173 157.272 176.071 157.362 175.974C157.322 176.099 157.261 176.217 157.181 176.321L156.556 177.336L154.123 181.186L152.358 183.966L150.342 187.316C148.952 189.735 147.229 192.445 145.575 195.573L142.92 200.424C141.989 202.12 141.127 203.941 140.14 205.789C138.222 209.459 136.415 213.518 134.455 217.757C130.386 227.032 126.786 236.505 123.668 246.141C120.652 255.802 118.129 265.609 116.107 275.526C115.245 280.113 114.383 284.463 113.827 288.578C113.535 290.635 113.202 292.609 112.965 294.527C112.729 296.445 112.548 298.28 112.34 300.087C111.881 303.59 111.714 306.787 111.478 309.581C111.367 310.971 111.27 312.277 111.172 313.487C111.075 314.696 111.061 315.766 111.019 316.767C110.922 318.699 110.839 320.214 110.783 321.312C110.783 321.799 110.783 322.188 110.783 322.494C110.741 322.644 110.68 322.789 110.602 322.925Z"
            fill="#FF725E"
          />
          <path
            d="M395.219 150.982C394.863 150.923 394.514 150.83 394.176 150.704L391.202 149.786C389.923 149.355 388.352 148.869 386.503 148.396C384.655 147.924 382.57 147.27 380.207 146.77L376.523 145.922C375.244 145.63 373.896 145.408 372.506 145.144C369.726 144.574 366.738 144.185 363.582 143.754C356.729 142.916 349.831 142.503 342.927 142.517C336.021 142.611 329.128 143.126 322.285 144.06C319.144 144.56 316.155 144.991 313.389 145.602C311.999 145.894 310.609 146.131 309.372 146.436L305.703 147.354C303.354 147.882 301.282 148.563 299.448 149.077C297.613 149.592 296.028 150.106 294.763 150.551L291.803 151.524C291.462 151.645 291.114 151.742 290.76 151.816C291.077 151.637 291.413 151.492 291.761 151.385L294.666 150.287C295.931 149.8 297.446 149.244 299.323 148.688C301.199 148.132 303.228 147.423 305.578 146.853L309.247 145.894C310.526 145.575 311.874 145.324 313.264 145.019C316.044 144.379 319.047 143.921 322.202 143.406C335.94 141.364 349.897 141.257 363.666 143.087C366.821 143.559 369.837 143.962 372.617 144.56C374.007 144.852 375.397 145.074 376.648 145.38L380.332 146.284C382.695 146.812 384.766 147.493 386.615 148.021C388.463 148.549 390.034 149.077 391.299 149.55L394.218 150.593C394.564 150.688 394.899 150.818 395.219 150.982Z"
            fill="#FF725E"
          />
          <path
            d="M449.443 214.296C449.186 214.07 448.953 213.819 448.748 213.546C448.261 212.99 447.636 212.253 446.857 211.322L445.467 209.709C444.967 209.139 444.383 208.542 443.771 207.874C442.534 206.582 441.2 205.025 439.601 203.496L437.044 201.036C436.14 200.188 435.139 199.382 434.153 198.506C432.179 196.727 429.899 195.031 427.536 193.21C417.096 185.49 405.427 179.587 393.022 175.752C390.145 174.931 387.462 174.097 384.821 173.569C383.529 173.277 382.292 172.958 381.068 172.735L377.58 172.138C375.356 171.693 373.312 171.54 371.533 171.317C370.643 171.206 369.809 171.095 369.045 171.026L366.96 170.97L364.055 170.761C363.713 170.755 363.373 170.713 363.04 170.636C363.378 170.603 363.717 170.603 364.055 170.636H366.974H369.087C369.865 170.636 370.699 170.775 371.589 170.859C373.382 171.039 375.439 171.165 377.677 171.568L381.194 172.138C382.417 172.332 383.668 172.652 384.974 172.93C387.601 173.43 390.34 174.32 393.231 175.057C405.747 178.844 417.506 184.785 427.981 192.612C430.344 194.475 432.637 196.185 434.597 197.992C435.584 198.895 436.585 199.715 437.475 200.577L440.018 203.079C441.659 204.636 442.979 206.22 444.188 207.555C444.772 208.222 445.356 208.834 445.842 209.431C446.329 210.029 446.76 210.585 447.149 211.099C447.9 212.044 448.539 212.823 448.942 213.407C449.135 213.687 449.303 213.985 449.443 214.296Z"
            fill="#FF725E"
          />
          <path
            d="M479.175 176.53C479.068 176.458 478.97 176.374 478.883 176.28L478.063 175.501L474.977 172.457L472.753 170.247C471.919 169.455 470.988 168.649 469.973 167.745C467.971 165.98 465.803 163.853 463.12 161.796L458.95 158.474C457.56 157.32 455.934 156.25 454.349 155.068C451.208 152.664 447.622 150.412 443.897 147.979C435.73 142.906 427.205 138.432 418.39 134.594C409.48 130.889 400.315 127.833 390.965 125.447C386.628 124.461 382.514 123.446 378.594 122.862C376.648 122.542 374.772 122.139 372.951 121.917L367.711 121.277C364.375 120.791 361.33 120.694 358.662 120.471C357.272 120.388 356.09 120.277 354.937 120.221H351.809L347.472 120.11H346.346H345.957C346.086 120.088 346.217 120.088 346.346 120.11H347.472H351.809H354.95C356.104 120.11 357.341 120.221 358.689 120.291C361.358 120.471 364.416 120.541 367.766 120.986L373.034 121.583C374.855 121.806 376.746 122.181 378.706 122.487C382.639 123.057 386.768 124.057 391.118 125.017C409.948 129.523 427.919 137.063 444.327 147.34C448.053 149.8 451.639 152.08 454.78 154.512C456.365 155.708 457.936 156.792 459.367 157.96L463.454 161.337C466.109 163.422 468.277 165.507 470.265 167.384C471.252 168.287 472.183 169.121 473.045 169.927L475.227 172.193L478.23 175.335L478.994 176.169C479.073 176.279 479.134 176.401 479.175 176.53Z"
            fill="#FF725E"
          />
          <path
            d="M575.682 313.792C575.665 313.673 575.665 313.551 575.682 313.431V312.333C575.682 311.332 575.682 309.928 575.599 308.163C575.599 307.246 575.599 306.245 575.599 305.147C575.599 304.049 575.488 302.839 575.432 301.547C575.279 298.975 575.224 295.987 574.876 292.817C574.737 291.205 574.598 289.509 574.431 287.744C574.265 285.979 574 284.158 573.764 282.267C573.347 278.486 572.624 274.469 571.916 270.244C570.174 261.126 567.918 252.114 565.16 243.25C562.335 234.393 559.017 225.701 555.222 217.215C553.387 213.337 551.705 209.626 549.884 206.276C548.994 204.594 548.188 202.926 547.313 201.397L544.783 196.977C543.226 194.197 541.614 191.681 540.265 189.485L538.361 186.441L536.665 183.939L534.344 180.464L533.746 179.546L533.565 179.227C537.8 184.816 541.694 190.656 545.228 196.713L547.799 201.119C548.689 202.662 549.509 204.316 550.412 205.998C552.261 209.348 553.971 213.059 555.819 216.937C559.672 225.433 563.018 234.15 565.841 243.041C568.58 251.959 570.799 261.027 572.485 270.202C573.166 274.372 573.875 278.459 574.265 282.267C574.487 284.172 574.751 285.992 574.89 287.758C575.029 289.523 575.168 291.233 575.293 292.845C575.833 299.814 575.963 306.808 575.682 313.792Z"
            fill="#FF725E"
          />
          <path
            d="M545.047 277.792C545.096 278.012 545.133 278.234 545.158 278.459C545.227 278.945 545.311 279.598 545.408 280.432C545.589 282.17 546.02 284.728 546.228 288.05C547.017 297.401 547.212 306.793 546.812 316.169C546.604 322.119 546.061 328.61 545.297 335.56C544.532 342.51 543.337 349.891 541.613 357.564C541.224 359.496 540.793 361.442 540.223 363.388L538.736 369.309C537.582 373.257 536.47 377.315 535.039 381.346C532.263 389.741 528.838 397.906 524.794 405.769C520.683 413.594 516.039 421.126 510.894 428.315C506.078 434.989 500.884 441.384 495.34 447.469C485.957 457.767 475.646 467.18 464.538 475.588C457.081 481.29 449.241 486.473 441.075 491.101C438.225 492.811 435.876 493.881 434.333 494.729L432.568 495.632C432.165 495.841 431.956 495.924 431.956 495.924C431.956 495.924 432.137 495.799 432.526 495.577L434.264 494.604C435.779 493.728 438.1 492.602 440.936 490.865C449.037 486.14 456.827 480.903 464.26 475.185C475.276 466.728 485.511 457.298 494.84 447.01C500.348 440.935 505.508 434.554 510.297 427.898C515.418 420.737 520.039 413.232 524.127 405.435C528.152 397.606 531.568 389.478 534.344 381.124C535.734 377.107 536.887 373.062 538.041 369.128C538.542 367.141 539.042 365.167 539.542 363.221C540.043 361.275 540.488 359.343 540.932 357.425C542.67 349.78 543.712 342.399 544.671 335.49C545.631 328.582 546.061 322.077 546.27 316.155C546.715 306.793 546.595 297.412 545.909 288.064C545.756 284.755 545.366 282.198 545.241 280.46C545.158 279.612 545.102 278.945 545.061 278.473C545.019 278 545.047 277.792 545.047 277.792Z"
            fill="#FF725E"
          />
          <path
            d="M481.83 217.896C481.83 217.896 481.969 218.021 482.205 218.313L483.261 219.564C484.151 220.69 485.583 222.275 487.209 224.499C491.899 230.665 496.127 237.169 499.858 243.959C502.263 248.24 504.584 253.091 506.905 258.373C509.308 264.028 511.401 269.81 513.174 275.693C515.146 282.193 516.697 288.814 517.817 295.514C519.047 302.706 519.772 309.975 519.985 317.267C520.174 324.556 519.909 331.849 519.193 339.104C518.43 345.859 517.26 352.562 515.69 359.176C511.137 377.917 503.123 395.642 492.06 411.44C490.531 413.72 489.169 415.36 488.321 416.527L487.306 417.82C487.2 417.972 487.084 418.116 486.959 418.251C487.035 418.082 487.134 417.923 487.251 417.778L488.196 416.388C489.002 415.207 490.336 413.539 491.824 411.231C496.176 404.847 500.064 398.157 503.458 391.215C508.507 380.925 512.395 370.104 515.051 358.954C516.589 352.389 517.731 345.737 518.47 339.035C519.182 331.807 519.442 324.542 519.248 317.281C519.034 310.025 518.309 302.793 517.08 295.639C515.983 288.963 514.455 282.366 512.507 275.887C510.759 270.022 508.699 264.255 506.335 258.609C504.028 253.341 501.748 248.49 499.385 244.209C495.71 237.412 491.562 230.882 486.973 224.666C485.388 222.428 483.998 220.815 483.136 219.662L482.135 218.355C482.019 218.212 481.917 218.059 481.83 217.896Z"
            fill="#FF725E"
          />
          <path
            d="M422.741 541.766L421.99 541.947L419.794 542.406C417.862 542.795 414.998 543.42 411.301 544.032C400.847 545.858 390.306 547.143 379.72 547.882C364.018 549.012 348.255 548.979 332.557 547.785C323.703 547.118 314.418 546.089 304.883 544.505C295.027 542.92 285.261 540.823 275.623 538.222C256.947 533.23 239.183 525.301 222.998 514.731C215.951 510.128 209.171 505.13 202.69 499.761C196.56 494.84 191.139 489.767 186.26 485.11C176.53 475.769 169.358 467.652 164.423 461.98C161.963 459.2 160.1 456.907 158.863 455.378L157.473 453.64L157.014 453.043L157.543 453.599L159.016 455.295C160.309 456.782 162.213 458.992 164.715 461.8C169.691 467.36 177.003 475.463 186.677 484.749C191.542 489.377 196.963 494.34 203.093 499.302C209.579 504.628 216.354 509.594 223.387 514.175C231.169 519.21 239.313 523.661 247.754 527.491C256.831 531.59 266.226 534.947 275.845 537.527C285.461 540.122 295.204 542.22 305.035 543.81C314.557 545.394 323.814 546.437 332.655 547.118C348.321 548.335 364.055 548.418 379.734 547.368C393.12 546.492 403.892 544.963 411.287 543.754C414.998 543.184 417.862 542.614 419.808 542.267L422.018 541.878L422.741 541.766Z"
            fill="#FF725E"
          />
          <path
            d="M345.874 518.303C339.546 518.636 333.204 518.557 326.886 518.067L322.285 517.705C320.687 517.58 319.047 517.33 317.323 517.122C313.89 516.76 310.262 516.079 306.439 515.426C289.846 512.318 273.773 506.89 258.693 499.302C255.26 497.509 251.951 495.841 248.963 494.048C247.476 493.172 246.016 492.366 244.654 491.504L240.79 489.002C235.448 485.568 230.34 481.784 225.5 477.673L225.778 477.854L226.584 478.424L229.614 480.676L231.783 482.33C232.589 482.914 233.492 483.511 234.451 484.179C236.37 485.485 238.496 487.056 241.012 488.571L244.89 491.031C246.28 491.879 247.67 492.658 249.199 493.533C252.16 495.299 255.454 496.925 258.929 498.704C273.992 506.182 289.997 511.587 306.509 514.772C310.318 515.44 313.932 516.162 317.351 516.524C319.061 516.746 320.701 517.01 322.285 517.149L326.872 517.566C329.778 517.872 332.432 517.914 334.754 518.039C335.921 518.039 336.992 518.164 337.992 518.192H340.772H344.553H345.54L345.874 518.303Z"
            fill="#FF725E"
          />
          <path
            d="M298.53 481.468C298.53 481.468 298.113 481.468 297.321 481.301L293.874 480.676L291.386 480.217C290.482 480.023 289.509 479.772 288.453 479.522C286.368 478.98 283.949 478.48 281.364 477.632L277.277 476.381C275.887 475.936 274.414 475.366 272.913 474.824C269.868 473.795 266.741 472.405 263.433 471.001C249.139 464.601 235.966 455.947 224.415 445.37C221.816 442.882 219.3 440.574 217.146 438.197C216.062 437.016 214.95 435.932 213.99 434.806L211.21 431.553C209.39 429.51 207.972 427.494 206.651 425.785C205.998 424.923 205.372 424.131 204.844 423.38L203.454 421.267L201.55 418.39C201.31 418.063 201.1 417.714 200.924 417.347C201.198 417.653 201.448 417.979 201.675 418.32L203.718 421.1L205.178 423.158C205.734 423.894 206.373 424.673 207.027 425.521C208.417 427.203 209.807 429.19 211.655 431.206L214.435 434.417C215.408 435.529 216.506 436.599 217.604 437.767C219.759 440.116 222.289 442.409 224.902 444.869C236.444 455.337 249.547 463.943 263.739 470.376C267.033 471.766 270.133 473.156 273.149 474.254C274.65 474.796 276.068 475.38 277.486 475.839L281.531 477.117C284.102 478.007 286.507 478.507 288.578 479.105C289.62 479.383 290.593 479.661 291.497 479.87L293.957 480.398L297.377 481.135C297.771 481.209 298.157 481.321 298.53 481.468Z"
            fill="#FF725E"
          />
          <path
            d="M352.059 581.52C351.914 581.559 351.765 581.582 351.614 581.59L350.224 581.701L345.151 582.118L341.481 582.382C340.091 582.382 338.701 582.466 337.103 582.507C333.961 582.507 330.375 582.744 326.414 582.507L320.214 582.327C318.046 582.257 315.808 582.007 313.487 581.84C308.844 581.576 303.91 580.867 298.725 580.2C276.205 576.917 254.311 570.248 233.784 560.42C229.1 558.071 224.61 555.917 220.607 553.554C218.591 552.386 216.59 551.344 214.755 550.204L209.501 546.896C206.109 544.852 203.246 542.726 200.66 540.905C199.382 539.987 198.172 539.167 197.088 538.361L194.197 536.081L190.208 532.912L189.179 532.078C188.957 531.884 188.832 531.786 188.845 531.772C188.859 531.758 188.984 531.772 189.221 532.023L190.291 532.801L194.364 535.859L197.297 538.083C198.381 538.875 199.604 539.668 200.897 540.571C203.496 542.322 206.373 544.449 209.779 546.451L215.047 549.717C216.882 550.843 218.869 551.872 220.899 553.025C224.902 555.361 229.392 557.501 234.062 559.823C254.569 569.568 276.399 576.242 298.85 579.63C304.021 580.311 308.928 581.02 313.57 581.326C315.878 581.507 318.115 581.771 320.27 581.854L326.455 582.076C330.403 582.313 333.975 582.076 337.117 582.076C338.687 582.076 340.147 582.076 341.495 582.076L345.165 581.854L350.252 581.59H351.642L352.059 581.52Z"
            fill="#FF725E"
          />
          <path
            d="M152.608 255.065C152.539 255.26 139.334 250.367 123.112 244.14C106.891 237.912 93.7972 232.714 93.9223 232.533C94.0474 232.352 107.197 237.245 123.432 243.459C139.667 249.672 152.678 254.884 152.608 255.065Z"
            fill="#FF725E"
          />
          <path
            d="M206.818 212.239C206.818 212.239 206.735 212.364 206.54 212.587L205.664 213.56C204.914 214.435 203.691 215.617 202.314 217.326C198.303 222.005 194.699 227.018 191.542 232.311C186.909 240.172 183.112 248.497 180.213 257.15C176.693 267.781 174.271 278.745 172.985 289.871C171.944 298.381 171.596 306.963 171.943 315.53V315.905H171.582L127.977 315.766L115.37 315.613H112.006L111.2 315.53C111.103 315.542 111.005 315.542 110.908 315.53C110.908 315.53 111.005 315.53 111.2 315.53H112.076H115.439L128.061 315.419L171.665 315.294L171.317 315.669C170.951 307.093 171.291 298.502 172.332 289.982C173.627 278.809 176.082 267.801 179.657 257.136C182.587 248.428 186.45 240.062 191.181 232.186C194.388 226.892 198.068 221.9 202.175 217.271C203.565 215.589 204.844 214.491 205.637 213.601L206.554 212.67C206.693 212.336 206.804 212.225 206.818 212.239Z"
            fill="#FF725E"
          />
          <path
            d="M238.621 424.728C238.29 425.256 237.909 425.749 237.481 426.202C236.703 427.119 235.563 428.412 234.118 429.997C231.24 433.166 227.168 437.447 222.428 441.978C217.688 446.51 213.254 450.416 209.918 453.098C208.264 454.488 206.915 455.559 205.97 456.295C205.504 456.708 204.996 457.072 204.455 457.379C204.873 456.898 205.339 456.46 205.845 456.073L209.626 452.709C212.823 449.86 217.215 445.898 221.927 441.381C226.639 436.863 230.768 432.651 233.742 429.579L237.273 425.938C237.679 425.489 238.131 425.084 238.621 424.728Z"
            fill="#FF725E"
          />
          <path
            d="M226.639 474.477C226.806 474.574 218.772 487.765 208.695 503.931C198.617 520.096 190.305 533.121 190.138 533.023C189.971 532.926 198.005 519.721 208.083 503.555C218.16 487.39 226.473 474.365 226.639 474.477Z"
            fill="#FF725E"
          />
          <path
            d="M293.79 484.095C293.612 484.808 293.374 485.506 293.082 486.18C292.539 487.64 291.817 489.53 290.955 491.81C289.148 496.564 286.688 503.124 284.102 510.422C281.517 517.719 279.279 524.322 277.694 529.201L275.804 534.914C275.607 535.626 275.346 536.319 275.025 536.985C275.138 536.255 275.306 535.535 275.526 534.83C275.901 533.44 276.471 531.48 277.208 529.048C278.598 524.183 280.822 517.497 283.421 510.186C286.02 502.874 288.55 496.286 290.482 491.629C291.441 489.266 292.248 487.376 292.831 486.069C293.09 485.383 293.411 484.722 293.79 484.095Z"
            fill="#FF725E"
          />
          <path
            d="M416.625 542.962C416.514 542.775 416.429 542.574 416.374 542.364C416.222 541.878 416.027 541.294 415.791 540.599L413.761 534.052C412.093 528.492 409.814 520.875 407.353 512.41C404.893 503.945 402.697 496.23 401.14 490.67C400.403 487.96 399.75 485.736 399.333 484.068L398.93 482.33C398.877 482.12 398.84 481.906 398.819 481.691C398.819 481.691 398.93 481.885 399.083 482.288C399.236 482.691 399.43 483.359 399.667 484.054C400.181 485.708 400.862 487.918 401.682 490.601C403.364 496.161 405.644 503.778 408.104 512.243C410.564 520.708 412.76 528.381 414.317 533.955C415.054 536.651 415.707 538.875 416.11 540.557L416.569 542.364C416.617 542.559 416.636 542.761 416.625 542.962Z"
            fill="#FF725E"
          />
          <path
            d="M430.803 494.548C430.608 494.548 426.508 484.318 421.643 471.53C416.778 458.742 412.969 448.317 413.15 448.247C413.33 448.178 417.445 458.478 422.31 471.266C427.175 484.054 430.983 494.479 430.803 494.548Z"
            fill="#FF725E"
          />
          <path
            d="M530.744 446.69C530.078 446.465 529.432 446.186 528.812 445.856C527.588 445.287 525.837 444.466 523.71 443.285C519.429 441.047 513.619 437.725 507.475 433.694C501.331 429.663 496.021 425.562 492.296 422.504C490.42 420.975 488.932 419.724 487.932 418.807C487.379 418.398 486.871 417.933 486.417 417.417C487.012 417.786 487.579 418.199 488.112 418.654L492.616 422.157C496.425 425.104 501.748 429.107 507.906 433.138C514.064 437.169 519.777 440.533 523.974 442.868L528.964 445.648C529.588 445.94 530.184 446.289 530.744 446.69Z"
            fill="#FF725E"
          />
          <path
            d="M595.351 393.467C595.351 393.662 580.978 390.284 563.381 385.934C545.783 381.583 531.508 377.885 531.564 377.691C531.619 377.496 545.922 380.874 563.534 385.225C581.145 389.575 595.393 393.273 595.351 393.467Z"
            fill="#FF725E"
          />
          <path
            d="M578.74 314.613C578.74 314.821 566.23 315.127 550.801 315.308C535.372 315.488 522.849 315.474 522.835 315.308C522.821 315.141 535.345 314.793 550.788 314.613C566.23 314.432 578.74 314.418 578.74 314.613Z"
            fill="#FF725E"
          />
          <path
            d="M544.046 277.638C544.046 277.833 516.899 278.472 483.4 279.028C449.901 279.584 422.741 279.932 422.741 279.737C422.741 279.543 449.887 278.903 483.4 278.347C516.913 277.791 544.046 277.444 544.046 277.638Z"
            fill="#FF725E"
          />
          <path
            d="M593.447 229.211C593.258 229.325 593.058 229.419 592.849 229.489L591.084 230.128L584.606 232.436C579.13 234.382 571.582 237.106 563.311 240.275C555.041 243.445 547.59 246.461 542.225 248.615L535.859 251.229L534.121 251.924C533.922 252.015 533.712 252.081 533.496 252.118C533.496 252.118 533.677 251.979 534.066 251.785L535.748 250.978C537.221 250.283 539.362 249.31 542.03 248.198C547.354 245.863 554.763 242.777 563.047 239.594C571.332 236.411 578.921 233.77 584.439 231.949C587.219 231.046 589.443 230.337 591 229.864L592.793 229.35C593.003 229.271 593.223 229.224 593.447 229.211Z"
            fill="#FF725E"
          />
          <path
            d="M486.959 176.335C487.084 176.488 479.022 183.8 468.958 192.668C458.895 201.536 450.61 208.611 450.485 208.458C450.36 208.305 458.408 200.994 468.472 192.126C478.535 183.258 486.82 176.183 486.959 176.335Z"
            fill="#FF725E"
          />
          <path
            d="M499.733 99.8159C499.9 99.941 491.754 111.645 481.552 125.976C471.349 140.307 462.939 151.816 462.773 151.705C462.606 151.593 470.751 139.876 480.968 125.545C491.184 111.214 499.566 99.7047 499.733 99.8159Z"
            fill="#FF725E"
          />
          <path
            d="M344.845 116.913C344.806 116.688 344.806 116.457 344.845 116.232C344.845 115.704 344.845 115.064 344.915 114.286C344.998 112.479 345.095 110.116 345.234 107.113C345.471 101.053 345.651 92.6852 345.457 83.4834C345.262 74.2816 344.72 65.8721 344.22 59.8534L343.608 52.7088L343.441 50.7628C343.405 50.5372 343.405 50.3072 343.441 50.0817C343.532 50.2931 343.583 50.5191 343.594 50.7489C343.677 51.2632 343.761 51.9026 343.886 52.681C344.122 54.349 344.414 56.851 344.734 59.8117C345.359 65.8443 345.985 74.226 346.124 83.4417C346.263 92.6574 346.054 101.095 345.679 107.155C345.498 110.185 345.318 112.632 345.137 114.327L344.928 116.273C344.92 116.489 344.892 116.703 344.845 116.913Z"
            fill="#FF725E"
          />
          <path
            d="M276.026 178.712C275.601 178.262 275.223 177.768 274.9 177.239C274.219 176.266 273.274 174.848 272.12 173.069C269.827 169.51 266.783 164.493 263.655 158.821C260.528 153.15 257.928 147.882 256.149 144.032C255.26 142.114 254.551 140.543 254.106 139.445C253.835 138.892 253.616 138.315 253.453 137.721C253.806 138.228 254.109 138.768 254.356 139.334L256.608 143.809C258.512 147.604 261.181 152.803 264.295 158.474C267.408 164.145 270.383 169.177 272.635 172.805L275.206 177.1C275.53 177.61 275.805 178.15 276.026 178.712Z"
            fill="#FF725E"
          />
          <path
            d="M442.298 261.473C442.287 261.316 442.287 261.158 442.298 261V259.61C442.298 258.415 442.298 256.733 442.228 254.592C442.228 250.228 442.145 244.001 442.076 236.369L442.354 236.647L395.01 236.731C394.69 237.064 395.455 236.3 395.372 236.383V249.296C395.372 253.466 395.372 257.539 395.372 261.403L395.024 261.056L428.968 261.223L438.795 261.32H441.422H442.367C442.081 261.34 441.793 261.34 441.506 261.32H438.934L429.204 261.403L395.038 261.57H394.704V261.237C394.704 257.372 394.704 253.314 394.704 249.13C394.704 247.031 394.704 244.96 394.704 242.75V236.217C394.621 236.286 395.385 235.522 395.066 235.841L442.409 235.939H442.687V236.203C442.687 243.903 442.576 250.186 442.534 254.592C442.534 256.705 442.534 258.359 442.465 259.555C442.465 260.083 442.465 260.5 442.465 260.833C442.439 261.053 442.383 261.269 442.298 261.473Z"
            fill="#FF725E"
          />
          <path
            d="M433.972 245.168C433.972 245.363 426.619 245.53 417.542 245.53C408.465 245.53 401.126 245.363 401.126 245.168C401.126 244.974 408.479 244.807 417.542 244.807C426.605 244.807 433.972 244.974 433.972 245.168Z"
            fill="#FF725E"
          />
          <path
            d="M433.972 250.561C433.972 250.756 426.619 250.923 417.542 250.923C408.465 250.923 401.126 250.756 401.126 250.561C401.126 250.367 408.479 250.2 417.542 250.2C426.605 250.2 433.972 250.367 433.972 250.561Z"
            fill="#FF725E"
          />
          <path
            d="M422.741 260.917C423.097 263.154 423.219 265.423 423.102 267.686C423.218 269.944 423.097 272.209 422.741 274.442C422.384 272.209 422.263 269.944 422.379 267.686C422.263 265.423 422.384 263.154 422.741 260.917Z"
            fill="#FF725E"
          />
          <path
            d="M615.117 371.13C615.117 371.13 615.117 370.963 615.117 370.657C615.117 370.352 615.117 369.879 615.117 369.267C615.117 368.072 615.117 366.39 615.047 364.249C615.047 359.885 614.964 353.672 614.908 346.027L615.172 346.305L567.912 346.388C567.579 346.721 568.343 345.957 568.274 346.04V349.307C568.274 350.405 568.274 351.489 568.274 352.573V358.953C568.274 363.124 568.274 367.196 568.274 371.06L567.94 370.713L601.87 370.88L611.697 370.977H614.338H615.27C614.987 370.997 614.704 370.997 614.422 370.977H611.85L602.12 371.06L567.954 371.227H567.607V371.13C567.607 367.266 567.607 363.207 567.607 359.023V346.11C567.523 346.11 568.288 345.415 567.968 345.735L615.228 345.832H615.506V346.11C615.506 353.824 615.395 360.093 615.353 364.5C615.353 366.612 615.353 368.267 615.353 369.462V370.741C615.296 370.883 615.216 371.014 615.117 371.13Z"
            fill="#FF725E"
          />
          <path
            d="M606.791 354.825C606.791 355.02 599.437 355.187 590.375 355.187C581.312 355.187 573.945 355.02 573.945 354.825C573.945 354.631 581.298 354.464 590.375 354.464C599.451 354.464 606.791 354.631 606.791 354.825Z"
            fill="#FF725E"
          />
          <path
            d="M606.791 360.219C606.791 360.427 599.437 360.58 590.375 360.58C581.312 360.58 573.945 360.427 573.945 360.219C573.945 360.01 581.298 359.857 590.375 359.857C599.451 359.857 606.791 360.01 606.791 360.219Z"
            fill="#FF725E"
          />
          <path
            d="M625.75 135.33H578.407V160.364H625.75V135.33Z"
            fill="white"
          />
          <path
            d="M625.736 160.364C625.736 160.364 625.736 160.198 625.736 159.892C625.736 159.586 625.736 159.113 625.736 158.502C625.736 157.292 625.736 155.611 625.667 153.484C625.667 149.105 625.583 142.892 625.528 135.261L625.792 135.525L578.462 135.622C578.129 135.942 578.893 135.178 578.824 135.261V141.794C578.824 143.962 578.824 146.089 578.824 148.174C578.824 152.344 578.824 156.417 578.824 160.281L578.49 159.947L612.42 160.114L622.247 160.198H624.888H625.82C625.537 160.217 625.254 160.217 624.972 160.198H622.4L612.67 160.295L578.504 160.462H578.157V160.114C578.157 156.25 578.157 152.205 578.157 148.007V135.344L578.518 134.983L625.847 135.066H626.125V135.344C626.125 143.045 626.014 149.244 625.986 153.734C625.986 155.833 625.986 157.501 625.986 158.696C625.986 159.211 625.986 159.642 625.986 159.961C625.927 160.109 625.842 160.245 625.736 160.364Z"
            fill="#FF725E"
          />
          <path
            d="M617.424 144.073C617.424 144.268 610.057 144.435 600.994 144.435C591.931 144.435 584.564 144.268 584.564 144.073C584.564 143.879 591.918 143.712 600.994 143.712C610.071 143.712 617.424 143.865 617.424 144.073Z"
            fill="#FF725E"
          />
          <path
            d="M617.424 149.467C617.424 149.661 610.057 149.828 600.994 149.828C591.931 149.828 584.564 149.661 584.564 149.467C584.564 149.272 591.918 149.105 600.994 149.105C610.071 149.105 617.424 149.258 617.424 149.467Z"
            fill="#FF725E"
          />
          <path
            d="M593.21 160.253C593.419 160.253 593.572 175.043 593.572 193.266C593.572 211.489 593.419 226.292 593.21 226.292C593.002 226.292 592.849 211.502 592.849 193.266C592.849 175.029 593.016 160.253 593.21 160.253Z"
            fill="#FF725E"
          />
          <path
            d="M76.8392 225.18C76.8196 225.023 76.8196 224.864 76.8392 224.707V223.387C76.8392 222.178 76.8392 220.496 76.8392 218.355C76.8392 213.991 76.7558 207.777 76.7002 200.146L76.9782 200.41L29.6348 200.508L29.9962 200.146V206.679C29.9962 208.834 29.9962 210.974 29.9962 213.059C29.9962 217.229 29.9962 221.302 29.9962 225.166L29.6626 224.833L63.5925 224.999L73.4198 225.083H76.0608H77.006C76.719 225.102 76.4312 225.102 76.1442 225.083H73.5727L63.8427 225.18L29.6765 225.347H29.19V225.18C29.19 221.316 29.19 217.271 29.19 213.073V200.16C29.1066 200.23 29.8711 199.465 29.5514 199.799L76.8948 199.882H77.1589V200.16C77.1589 207.861 77.0477 214.13 77.0199 218.55C77.0199 220.649 77.0199 222.317 77.0199 223.512C77.0199 224.026 77.0199 224.443 77.0199 224.777C77.0199 225.111 76.8392 225.18 76.8392 225.18Z"
            fill="#FF725E"
          />
          <path
            d="M68.5131 208.847C68.5131 209.056 61.16 209.209 52.0833 209.209C43.0066 209.209 35.6674 209.056 35.6674 208.847C35.6674 208.639 43.0066 208.486 52.0833 208.486C61.16 208.486 68.5131 208.653 68.5131 208.847Z"
            fill="#FF725E"
          />
          <path
            d="M68.5131 214.254C68.5131 214.449 61.16 214.616 52.0833 214.616C43.0066 214.616 35.6674 214.449 35.6674 214.254C35.6674 214.06 43.0066 213.893 52.0833 213.893C61.16 213.893 68.5131 214.06 68.5131 214.254Z"
            fill="#FF725E"
          />
          <path
            d="M161.977 489.919H114.633V514.953H161.977V489.919Z"
            fill="white"
          />
          <path
            d="M161.977 514.953C161.957 514.796 161.957 514.638 161.977 514.481V513.16C161.977 511.951 161.977 510.269 161.977 508.128C161.977 503.764 161.893 497.55 161.824 489.919L162.102 490.183L114.758 490.281L115.12 489.919V502.832C115.12 507.002 115.12 511.075 115.12 514.939L114.786 514.606L148.73 514.773L158.543 514.856H161.184H162.13C161.843 514.876 161.555 514.876 161.268 514.856H158.696L148.966 514.953L114.8 515.12H114.466V514.773C114.466 510.908 114.466 506.863 114.466 502.666V489.753L114.828 489.391L162.171 489.475H162.449V489.753C162.449 497.453 162.338 503.722 162.31 508.128C162.31 510.241 162.31 511.909 162.241 513.105C162.241 513.619 162.241 514.036 162.241 514.369C162.184 514.577 162.095 514.774 161.977 514.953Z"
            fill="#FF725E"
          />
          <path
            d="M153.651 498.649C153.651 498.857 146.297 499.01 137.221 499.01C128.144 499.01 120.805 498.857 120.805 498.649C120.805 498.44 128.158 498.287 137.221 498.287C146.284 498.287 153.651 498.454 153.651 498.649Z"
            fill="#FF725E"
          />
          <path
            d="M153.651 504.056C153.651 504.25 146.297 504.417 137.221 504.417C128.144 504.417 120.805 504.25 120.805 504.056C120.805 503.861 128.158 503.694 137.221 503.694C146.284 503.694 153.651 503.847 153.651 504.056Z"
            fill="#FF725E"
          />
          <path
            d="M445.036 71.1679C445.036 71.1679 445.036 70.9872 445.036 70.6953C445.036 70.4034 445.036 69.903 445.036 69.3053C445.036 68.096 445.036 66.4141 444.967 64.2874C444.967 59.9089 444.883 53.6956 444.828 46.0645L445.092 46.3425L397.762 46.4259C397.429 46.7456 398.193 45.9811 398.11 46.0645V58.9776C398.11 63.1476 398.11 67.2064 398.11 71.0845L397.762 70.737L431.706 70.9038L441.533 71.0011H444.174H445.106C445.106 71.0011 444.828 71.0011 444.244 71.0011H441.686L431.956 71.0984L397.79 71.2652H397.457V71.1679C397.457 67.2898 397.457 63.2449 397.457 59.061V52.6809C397.457 51.5967 397.457 50.5125 397.457 49.4144V46.1479C397.387 46.2313 398.152 45.4668 397.832 45.7865L445.161 45.8699H445.439V46.134C445.37 53.8346 445.328 60.1174 445.286 64.5237C445.286 66.6365 445.286 68.2906 445.217 69.486V70.7648C445.179 70.908 445.118 71.0441 445.036 71.1679Z"
            fill="#FF725E"
          />
          <path
            d="M436.71 54.8632C436.71 55.0578 429.357 55.2246 420.294 55.2246C411.231 55.2246 403.864 55.0578 403.864 54.8632C403.864 54.6686 411.218 54.5018 420.294 54.5018C429.371 54.5018 436.71 54.6547 436.71 54.8632Z"
            fill="#FF725E"
          />
          <path
            d="M436.71 60.2564C436.71 60.451 429.357 60.6178 420.294 60.6178C411.231 60.6178 403.864 60.451 403.864 60.2564C403.864 60.0618 411.218 59.895 420.294 59.895C429.371 59.895 436.71 60.0618 436.71 60.2564Z"
            fill="#FF725E"
          />
          <path
            d="M87.9036 230.74C87.3692 230.854 86.8234 230.905 86.2773 230.893L81.8432 231.004C78.0902 231.004 72.9194 231.101 67.2065 231.101C61.2156 231.101 55.8224 231.101 52.0833 230.99H51.8331V230.74C51.8331 229.128 51.9165 227.751 51.9443 226.639C51.9328 226.14 51.9795 225.641 52.0833 225.152C52.1963 225.639 52.243 226.14 52.2223 226.639C52.2223 227.751 52.2223 229.128 52.3335 230.74L52.0833 230.504C55.8224 230.434 61.2156 230.379 67.2065 230.379C72.9194 230.379 78.0902 230.379 81.8432 230.49L86.2773 230.601C86.8227 230.59 87.3679 230.637 87.9036 230.74Z"
            fill="#FF725E"
          />
          <path
            d="M401.223 148.243C401.252 147.972 401.318 147.705 401.418 147.451C401.585 146.881 401.807 146.145 402.085 145.255L404.559 137.165C406.603 130.34 409.258 120.833 411.676 110.227C412.844 105.112 413.859 100.205 414.72 95.7293C414.581 88.5708 414.456 82.4409 414.373 77.9929C414.373 75.894 414.373 74.1843 414.373 72.9055C414.339 72.3023 414.362 71.6973 414.442 71.0985C414.532 71.6969 414.583 72.3005 414.595 72.9055C414.595 74.1843 414.734 75.8801 414.818 77.979C414.971 82.427 415.179 88.5569 415.429 95.7154C414.609 100.205 413.608 105.126 412.441 110.241C410.008 120.874 407.284 130.382 405.102 137.179C404.017 140.585 403.072 143.323 402.405 145.199L401.613 147.368C401.524 147.676 401.393 147.971 401.223 148.243Z"
            fill="#FF725E"
          />
          <path
            d="M204.511 504.056C204.511 504.25 195.531 504.417 184.467 504.417C173.403 504.417 164.409 504.25 164.409 504.056C164.409 503.861 173.389 503.694 184.467 503.694C195.545 503.694 204.511 503.847 204.511 504.056Z"
            fill="#FF725E"
          />
          <path
            d="M608.639 561.046C609.835 556.876 612.392 552.9 616.34 551.191C620.288 549.481 625.236 550.426 629.448 551.955C633.659 553.484 637.788 555.541 642.277 555.819C646.767 556.097 651.799 553.971 653.133 549.689C654.398 545.672 651.98 541.349 648.963 538.458C645.756 535.371 641.824 533.141 637.528 531.974C633.233 530.806 628.712 530.738 624.384 531.777C620.055 532.815 616.058 534.927 612.76 537.916C609.462 540.906 606.969 544.677 605.512 548.883"
            fill="#FF725E"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M608.639 561.046C609.835 556.876 612.392 552.9 616.34 551.191C620.288 549.481 625.236 550.426 629.448 551.955C633.659 553.484 637.788 555.541 642.277 555.819C646.767 556.097 651.799 553.971 653.133 549.689C654.398 545.672 651.98 541.349 648.963 538.458C645.756 535.371 641.824 533.141 637.528 531.974C633.233 530.806 628.712 530.738 624.384 531.777C620.055 532.815 616.058 534.927 612.76 537.916C609.462 540.906 606.969 544.677 605.512 548.883"
              fill="#CFAAFF"
            />
          </g>
          <path
            d="M573.18 579.018C566.62 572.777 566.939 561.921 570.22 553.484C573.5 545.047 580.645 538.555 588.623 534.288C596.415 530.204 605.03 527.935 613.822 527.648C622.615 527.362 631.359 529.066 639.4 532.634C635.469 532.814 631.641 533.951 628.249 535.947C624.858 537.943 622.005 540.738 619.94 544.088C616.354 550.065 615.548 557.307 612.573 563.617C609.222 570.731 603.273 576.292 595.949 579.156C588.626 582.021 580.483 581.971 573.194 579.018"
            fill="#CFAAFF"
          />
          <path
            d="M570.025 541.544C562.755 532.968 561.782 519.193 562.853 508.003C563.923 496.814 566.522 485.541 564.757 474.435C563.714 467.86 561.157 461.577 560.295 454.975C559.433 448.372 560.656 440.908 565.772 436.641C571.651 431.734 580.923 432.888 587.039 437.489C593.155 442.09 596.769 449.331 599.298 456.532C604.997 472.364 607.083 489.892 602.412 506.071C597.742 522.251 586.761 536.651 570.651 541.558"
            fill="#CFAAFF"
          />
          <path
            d="M566.536 611.948C566.506 611.619 566.506 611.289 566.536 610.961C566.536 610.307 566.536 609.348 566.536 608.181C566.62 605.72 566.87 602.162 567.412 597.784C568.056 592.662 568.984 587.581 570.192 582.563C571.699 576.404 574.037 570.48 577.142 564.952C582.805 554.894 590.769 546.32 600.383 539.932C602.204 538.667 603.955 537.722 605.47 536.832C606.985 535.942 608.375 535.359 609.473 534.816L612.1 533.718C612.397 533.581 612.704 533.465 613.018 533.371C612.744 533.556 612.45 533.71 612.142 533.83L609.584 535.025C608.486 535.595 607.152 536.29 605.637 537.124C604.122 537.958 602.412 539.014 600.619 540.279C591.151 546.752 583.294 555.311 577.656 565.299C570.984 576.989 569.149 589.277 567.815 597.895C567.217 602.245 566.912 605.79 566.759 608.25L566.592 611.03C566.594 611.337 566.575 611.643 566.536 611.948Z"
            fill="#263238"
          />
          <path
            d="M503.833 507.698C490.392 515.815 490.434 523.196 492.713 530.174C494.993 537.152 503.222 541.989 510.06 539.376C513.925 537.902 516.732 534.538 519.123 531.161C521.514 527.783 523.766 524.211 527.116 521.737C530.466 519.262 535.345 518.387 538.708 520.791"
            fill="#FF725E"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M503.833 507.698C490.392 515.815 490.434 523.196 492.713 530.174C494.993 537.152 503.222 541.989 510.06 539.376C513.925 537.902 516.732 534.538 519.123 531.161C521.514 527.783 523.766 524.211 527.116 521.737C530.466 519.262 535.345 518.387 538.708 520.791"
              fill="#CFAAFF"
            />
          </g>
          <path
            d="M493.77 516.593C497.286 511.742 505.974 510.325 511.464 512.757C516.955 515.19 520.958 519.985 524.558 524.781C528.158 529.576 531.661 534.65 536.637 537.999C541.613 541.349 548.577 542.545 553.54 539.181C555.125 538 556.453 536.509 557.442 534.798C558.432 533.086 559.062 531.192 559.294 529.229C559.725 525.307 559.457 521.339 558.502 517.511C557.307 511.867 555.374 505.974 550.857 502.388C545.825 498.398 538.847 498.218 532.426 498.426C525.059 498.662 517.58 499.219 510.672 501.762C503.764 504.306 496.703 509.838 493.77 516.593Z"
            fill="#CFAAFF"
          />
          <path
            d="M555.361 601.175C555.343 601.079 555.343 600.98 555.361 600.883C555.361 600.647 555.361 600.369 555.361 600.021C555.361 599.201 555.291 598.089 555.236 596.672C555.152 593.725 555.041 589.541 554.888 584.398C554.735 579.255 554.513 573.055 554.137 566.203C553.762 559.35 553.248 551.844 552.219 543.963C551.777 540.151 551.028 536.381 549.981 532.69C547.934 526.178 544.784 520.066 540.668 514.62C538.943 512.395 537.042 510.312 534.983 508.393C531.982 505.625 528.592 503.311 524.92 501.526C524.266 501.234 523.696 500.928 523.182 500.72L521.862 500.219L521.055 499.886C520.875 499.816 520.791 499.775 520.791 499.761L521.069 499.83L521.903 500.094L523.293 500.553C523.822 500.748 524.405 501.026 525.073 501.318C528.816 503.033 532.276 505.311 535.331 508.073C537.419 509.999 539.352 512.085 541.113 514.314C543.069 516.936 544.794 519.722 546.27 522.64C547.966 525.816 549.362 529.143 550.44 532.579C551.501 536.301 552.254 540.103 552.692 543.949C553.734 551.802 554.235 559.336 554.582 566.189C554.93 573.041 555.097 579.213 555.208 584.398C555.319 589.583 555.347 593.78 555.361 596.685C555.361 598.075 555.361 599.215 555.361 600.021C555.361 600.383 555.361 600.661 555.361 600.897C555.361 601.133 555.361 601.175 555.361 601.175Z"
            fill="#263238"
          />
          <path
            d="M560.17 607.249C559.531 605.367 559.038 603.439 558.697 601.481C557.762 596.126 557.557 590.669 558.085 585.26C558.902 577.244 560.618 569.345 563.2 561.713C565.98 553.053 569.705 543.81 572.93 533.913C574.508 529.083 575.794 524.163 576.781 519.179C577.677 514.533 578.317 509.842 578.699 505.126C579.304 497.125 579.448 489.096 579.13 481.079C578.893 474.226 578.532 468.694 578.296 464.858C578.184 462.981 578.101 461.494 578.045 460.451C578.045 459.979 578.045 459.59 578.045 459.298C578.027 459.169 578.027 459.038 578.045 458.909C578.059 459.038 578.059 459.168 578.045 459.298C578.045 459.59 578.115 459.965 578.157 460.438C578.254 461.494 578.379 462.967 578.532 464.844C578.838 468.666 579.255 474.199 579.547 481.065C579.94 489.098 579.838 497.147 579.241 505.168C578.874 509.906 578.248 514.621 577.364 519.29C576.375 524.298 575.084 529.241 573.5 534.094C570.289 544.074 566.55 553.317 563.77 561.894C561.18 569.482 559.428 577.332 558.544 585.301C557.984 590.681 558.124 596.11 558.961 601.453C559.294 603.33 559.6 604.789 559.864 605.762L560.142 606.888C560.165 607.007 560.175 607.128 560.17 607.249Z"
            fill="#263238"
          />
          <path
            d="M593.544 613.338H530.382L528.951 601.175H593.544V613.338Z"
            fill="#455A64"
          />
          <path
            d="M537.166 611.948L545.061 648.407H579.38L586.761 611.948H537.166Z"
            fill="#455A64"
          />
          <path
            d="M593.53 613.338C593.53 613.504 579.63 613.588 562.519 613.546C545.408 613.504 531.508 613.324 531.508 613.157C531.508 612.99 545.408 612.893 562.519 612.948C579.63 613.004 593.53 613.171 593.53 613.338Z"
            fill="#455A64"
          />
          <path
            d="M582.702 631.324C580.984 630.042 579.117 628.974 577.142 628.141C574.983 627.29 572.692 626.819 570.373 626.751C567.523 626.757 564.699 627.275 562.033 628.28C559.155 629.253 556.473 630.393 553.846 631.06C551.519 631.552 549.122 631.623 546.77 631.268C545.189 631.058 543.654 630.588 542.225 629.878C541.844 629.678 541.482 629.446 541.141 629.183C540.891 629.003 540.78 628.905 540.793 628.892C540.807 628.878 541.336 629.197 542.336 629.67C543.77 630.297 545.287 630.714 546.84 630.907C549.136 631.193 551.463 631.081 553.72 630.573C556.25 629.934 558.947 628.794 561.838 627.793C564.56 626.769 567.45 626.259 570.359 626.292C572.75 626.353 575.109 626.867 577.309 627.807C578.799 628.415 580.201 629.219 581.479 630.198C581.824 630.462 582.153 630.745 582.466 631.046C582.605 631.199 582.716 631.31 582.702 631.324Z"
            fill="#263238"
          />
          <path
            d="M582.215 633.84C580.566 632.582 578.775 631.523 576.878 630.685C574.81 629.821 572.599 629.35 570.359 629.295C567.615 629.285 564.892 629.755 562.311 630.685C559.531 631.616 556.917 632.686 554.429 633.367C552.222 634.001 549.924 634.255 547.632 634.118C546.08 634.031 544.562 633.629 543.17 632.937C542.801 632.738 542.447 632.51 542.114 632.255C541.892 632.089 541.766 631.991 541.78 631.977C541.794 631.964 542.309 632.269 543.282 632.714C544.668 633.311 546.152 633.645 547.66 633.701C549.898 633.768 552.133 633.472 554.276 632.825C556.723 632.144 559.308 631.046 562.13 630.115C564.77 629.152 567.563 628.681 570.373 628.725C572.672 628.793 574.937 629.303 577.045 630.226C578.46 630.845 579.788 631.649 580.992 632.617C581.317 632.874 581.628 633.147 581.924 633.437C582.137 633.631 582.234 633.766 582.215 633.84Z"
            fill="white"
          />
          <path
            d="M583.327 628.906C581.546 627.587 579.632 626.459 577.614 625.542C575.409 624.597 573.059 624.033 570.664 623.874C567.734 623.817 564.821 624.332 562.088 625.389C559.323 626.479 556.496 627.407 553.623 628.169C551.204 628.587 548.731 628.587 546.312 628.169C544.679 627.915 543.095 627.414 541.613 626.682C541.221 626.48 540.844 626.247 540.488 625.987C540.237 625.792 540.112 625.695 540.126 625.681C540.14 625.667 540.682 626 541.725 626.473C543.211 627.111 544.778 627.542 546.381 627.752C548.75 628.098 551.159 628.051 553.512 627.613C556.339 626.836 559.123 625.908 561.852 624.833C564.659 623.749 567.657 623.243 570.664 623.346C574.768 623.668 578.687 625.186 581.937 627.71C582.295 627.979 582.634 628.272 582.952 628.586C583.23 628.78 583.341 628.892 583.327 628.906Z"
            fill="white"
          />
          <path
            d="M594.1 613.379C594.1 613.546 579.894 613.685 562.366 613.685C544.838 613.685 530.646 613.546 530.646 613.379C530.646 613.212 544.852 613.087 562.366 613.087C579.88 613.087 594.1 613.212 594.1 613.379Z"
            fill="#263238"
          />
          <defs>
            <filter
              id="filter0_d"
              x="58.5051"
              y="649.297"
              width="585.976"
              height="8.72284"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dx="4" dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <div className="my-20 ml-20 text-left space-y-3 mr-20" id="#about">
        <p className="text-purple-500 font-bold text-6xl pb-4">
          About me
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="9"
            viewBox="0 0 46 9"
            fill="none"
          >
            <path d="M0 4.5H46" stroke="#400FCB" stroke-width="8" />
          </svg>
        </p>
        <p className="text">
          I graduated from Immanuel Vocational High School in Pontianak with a
          major in computer. I currently graduated from Intensive Bootcamp by
          Glints Academy. In the bootcamp, I learned a lot about getting used to
          using javascript, creating Rest Api with Nodejs express and etc.
        </p>
        <p className="text">
          I also completed several projects from the bootcamp in the form of a
          mini project called Laflix, a movie review and a final project called
          Sweethome. You can see the results of my projects later on the
          projects page.
        </p>
      </div>
    </div>
  );
};

export default About;
